.modalDivHeading {
    font-weight: var(--fontWeight1);
    font-size: var(--fontSize1);
    line-height: 24px;
    color: #591438;
    margin-top: 40px;
    text-align: center;
}

.modalDivp {
    font-weight: var(--fontWeight3);
    font-size: var(--fontSize3);
    line-height: 20px;
    margin-top: 32px;
    margin-right: 32px;
    margin-left: 32px;
    color: #73696E;

}

.tooltipChangeStatus {
    /* box-shadow: 5px 10px 8px #F4E0EB */
    box-shadow: -5px -5px 20px #F4E0EB;
    padding: 10px 10px;
    background-color: var(--bgColor1);
}

.btnDiv {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 40px;
}

.cancel {
    width: 110px;
    height: 40px;
    padding: 8px 16px;
    background: #92215C;
    border: 1px solid #92215C;
    border-radius: 4px;
    font-weight: var(--fontWeight2);
    font-size: var(--fontSize4);
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.discard {
    width: 101px;
    height: 40px;
    padding: 8px 16px;
    background: #FFFFFF;
    border: 1px solid #92215C;
    border-radius: 4px;
    cursor: pointer;
    font-weight: var(--fontWeight3);
    font-size: var(--fontSize4);
    line-height: 16px;
    text-align: center;
    color: #92215C;
}