.btn {
  border-radius: 3px;
  border: 1px solid var(--textColorActive);
  background: #fff;
  height: 40px;
  padding: 10px 20px;
  color: var(--textColorActive);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
