.mainDiv {
    width: 100%;
    padding-bottom: 40px;
    background: #FFFFFF;
    border: 1px solid #ECEBEC;
    border-radius: 4px;
}

.titleDiv {
    background: var(--bgcolor);
    border-radius: 4px 4px 0px 0px;
    padding-left: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 4px;
}

.titleDiv>p {
    font-weight: var(--fontWeight2);
    font-size: var(--fontSize2) !important;
    line-height: 20px;
    color: var(--textColorActive1) !important;
}

.browseBtn {
    width: 220px;
    height: 40px;
    border: 1px solid #DEDFE1;
    border-radius: 3px;
    font-weight: var(--fontWeight1);
    font-size: var(--fontSize2);
    line-height: 20px;
    color: var(--fontColorNormal2);
    cursor: pointer;
}

.uploadImgDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    margin-left: 24px;
    margin-right: 24px;
    border: 1px dashed #DEDFE1;
    /* background-color: grey; */
    padding: 24px;
}

.imgIconDiv {
    width: 160px;
    height: 160px;
    position: relative;
}

.imgIconDiv>img {
    width: 100%;
    height: 100%;
}

.previewDiv {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap
}

.closeIcon {
    position: absolute;
    right: -5px;
    top: -7px;
    cursor: pointer;
}

.imgSizeText {
    color: #121212;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.imgSizeText>span {
    color: #74676E
}

.error {
    padding-left: 23px;
    color: red;
}

.coverDiv {
    position: absolute;
    display: flex;
    bottom: 9px;
    background: #EFEFEF;
    border-radius: 8px;
    height: 24px;
    width: 152px;
    gap: 10px;
    align-items: center;
    justify-content: center;
    left: 3px;
}

.star {
    color: var(--statusClose);
}