.tableOuterDiv {
  margin: 10px 0px 0px 24px;
  height: 480px;
  position: relative;
}

.tableDiv {
  width: 98.2%;
  border-collapse: collapse;
  table-layout: fixed;
  display: block;
  height: 480px;
  overflow-y: auto;
}

.tHeadDiv {
  display: block;
  max-height: 350px;
  position: sticky;
  top: 0;
  z-index: 6;
}

.tHeadDiv th:first-child {
  width: 95px;
  top: 0;
  left: 0;
  position: sticky;
  z-index: 6;
}

.tHeadDiv th {
  padding: 6px 18px;
  background-color: #f8ecf3;
  /* font-weight: 100; */
}

.tBodyDiv th:first-child {
  width: 95px;
  left: 0;
  position: sticky;
  /* z-index: 4; */
  /* background-color: #f8ecf3; */
}

.tBodyDiv td,
.tBodyDiv th {
  /* padding: 15px; */
  height: 100px;
  font-weight: 100;
}

.tBodyDiv td {
  padding: 0px 5px 0px 0px;
}

.tHeadDiv tr,
.tBodyDiv tr {
  display: table;
  width: 200px;
  table-layout: fixed;
}

.tBodyDiv td,
.tBodyDiv th {
  border: 1px solid #dddddd;
  text-align: left;
  word-wrap: break-word;
}

.tHeadDiv th {
  text-align: left;
  word-wrap: break-word;
}

.currentDateDiv {
  border-top: 2.5px solid #591438;
  border-right: 1px solid #dddddd;
  color: #591438;
  font-weight: bold;
  font-size: 15px;
  width: 11.69vw;
}

.nonCurrentDayDiv {
  width: 11.69vw;
  font-size: 14px;
  font-weight: 100;
  border: 1px solid #dddddd;
}

.singleDayDiv {
  width: 84vw;
}

.multipleDaysDiv {
  width: 11.69vw;
}

.timeSlotDiv {
  cursor: pointer;
  color: black;
}

/* .tBodyDiv td:hover {
  background-color: #f8ecf3;
} */

.stripDiv {
  background-color: #f8ecf3;
  margin: 4px 0px;
  border-left: 2px solid #92215c;
  border-radius: 0px 4px 4px 0px;
  padding: 3px 10px;
  font-size: 12px;
}

.plusMoreDiv {
  background-color: rgba(250, 250, 250, 1);
  margin: 4px 0px;
  border-left: 2px solid rgba(116, 103, 110, 1);
  border-radius: 0px 4px 4px 0px;
  padding: 3px 10px;
  font-size: 12px;
}
.filterCrossIcon {
  cursor: pointer;
}
