.viewDetailsModalMain {
  height: 460px;
  width: 650px;
}

/* .toggleFullCardDiv {
  margin: 30px;
} */

.textInpDiv {
  /* padding: 24px 0px; */
  margin: 0px 35px 26px 30px;
}

.textInpDiv > div:first-child {
  padding-bottom: 10px;
}

.searchDiv {
  background: rgba(250, 250, 250, 1);
  width: 100%;
}

.inpDiv {
  background: rgba(250, 250, 250, 1);
  height: 35px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 0px 20px;
}

.nameImgDiv {
  display: flex;
  gap: 15px;
  align-items: center;
}

.toggleCardOuterDiv {
  margin: 0px 30px 10px;
}

.scrollDiv {
  overflow-y: auto;
  height: 45vh;
}

.toggleCardDiv {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(236, 235, 236, 1);
  border-radius: 4px;
  padding: 10px 20px;
  gap: 12px;
}

.btnDiv {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 40px;
}

.cancelBtn {
  width: 200px;
  height: 40px;
  left: calc(50% - 200px / 2 - 288px);
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize2);
  line-height: 16px;
  color: var(--textColorActive);
  background: var(--bgColor1);
  border: 1px solid var(--textColorActive);
  border-radius: 3px;
}

.closeBtn {
  width: 200px;
  height: 40px;
  left: calc(50% - 118px / 2 - 105px);
  padding: 12px 36px;
  background: var(--textColorActive);
  border-radius: 3px;
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize2);
  line-height: 16px;
  color: var(--bgColor1);
}
.noDataMainDiv {
  height: 460px;
  width: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 70px;
}
.noDataDiv {
  /* width: 400px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 80px; */
  /* border: 1px solid rgb(232, 229, 229); */
}
.noDataDiv > h2 {
  color: #121212;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
}
.noDataDiv > p {
  color: #74676e;
  font-size: 16px;
  line-height: 28px;
  margin-top: 16px;
}
.noDataBtn {
  width: 160px;
  height: 40px;
  padding: 10px 20px;
  border-radius: 3px;
  border: 1px solid #92215c !important;
  background: #fff;
  color: #92215c !important;
}
.noDataImgDiv {
  width: 225px;
  height: 200px;
}
.noDataImgDiv > img {
  width: 100%;
  height: 100%;
}
.noServFoundCont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.noServFound {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  color: rgba(105, 10, 60, 1);
}
.servInfoCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.discImgCont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.disclaimer {
  font-size: 12px;
  color: #ab0000;
}
.toggleCardDivDisc {
  border: none;
  background: #f2f2f2;
}
