.modalDivHeading {
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize1);
  line-height: 24px;
  color: #591438;
  margin-top: 40px;
  text-align: center;
}

.modalDivp {
  font-weight: var(--fontWeight3);
  font-size: 20px;
  line-height: 20px;
  margin-top: 16px;
  margin-right: 32px;
  margin-left: 32px;
  margin-bottom: 30px;
  color: #121212;
  text-align: center;
}

.modalDivp1 {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize3);
  line-height: 20px;
  margin-top: 16px;
  margin-right: 32px;
  margin-left: 32px;
  color: #73696e;
  text-align: center;
  margin-bottom: 24px;
}

.modalDivp2 {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize3);
  line-height: 20px;
  margin-top: 16px;
  margin-right: 32px;
  margin-left: 32px;
  color: var(--statusClose);
  text-align: center;
  margin-bottom: 24px;
  word-break: break-all;
  max-width: 330px;
}

.tooltipChangeStatus {
  /* box-shadow: 5px 10px 8px #F4E0EB */
  box-shadow: -5px -5px 20px #f4e0eb;
  padding: 10px 30px;
  background-color: var(--bgColor1);
  min-width: 109px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btnDiv {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 40px;
}

.cancel {
  width: 110px;
  height: 40px;
  padding: 8px 16px;
  background: #92215c;
  border: 1px solid #92215c;
  border-radius: 4px;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.discard {
  width: 80px;
  height: 40px;
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #c5c1c3;
  border-radius: 4px;
  cursor: pointer;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize4);
  line-height: 16px;
  text-align: center;
  color: #73696e;
}

.imgDiv {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
