.mainDivBookEventModal {
  border-radius: 8px;
}

.mainDivBookEventModal > div {
  padding-left: 50px;
  padding-right: 50px;
}

.scrollDiv {
  height: 70vh;
  overflow-y: auto;
  margin-bottom: 10px;
}

.headingBookEventModal {
  margin-top: 32px;
  color: var(--textColorActive1);
  font-weight: var(--fontWeight2) !important;
  font-size: 24px !important;
  line-height: 28px;
  text-align: center;
  margin-bottom: 16px;
}

.displayFlexDiv {
  display: flex;
  gap: 40px;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
  justify-content: space-between;
}

.displayFlexDiv > div {
  width: 350px;
}

.labelFont {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2) !important;
}
.inputText {
  margin-top: 12px;
  width: 100% !important;
  height: 44px !important;
}

.mainDiv {
  /* width: 1252px !important; */
  /* height: 740px; */
  margin-bottom: 32px;
  padding-bottom: 40px;
  background: var(--bgColor1);
  border: 1px solid var(--borderColor);
  border-radius: 4px;
}
.titleDiv {
  background: var(--bgcolor);
  border-radius: 4px 4px 0px 0px;
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 4px;
}

.titleDiv > p {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 20px;
  color: var(--textColorActive1) !important;
}

.btnDivBookEventDetail {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 32px;
  margin-bottom: 32px;
  /* border: 2px solid green; */
}

.chargeNpriceDiv {
  display: flex;
  gap: 25px;
}
.chargeDiv {
  width: 100%;
}
.priceTimeDiv {
  display: flex;
  /* width: 26rem; */
  margin-top: 12px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dedfe1;
  border-radius: 4px;
}
.rupee {
  position: absolute;
  top: 43px;
  left: 5%;
  font-size: 16px;
}
.inputTextPriceTime1 {
  border: none;
  width: 100%;
  height: 40px;
  outline: none;
  padding-left: 24px !important;
}
.rupee1 {
  position: absolute;
  top: 10px;
  left: 5%;
  font-size: 16px;
}
.inputTextPriceTime {
  border: none;
  width: 11rem;
  height: 40px;
  outline: none;
  padding-left: 24px !important;
}
.star {
  color: var(--statusClose);
}
.timePickerMainDiv {
  display: flex;
  gap: 2rem;
}

/* image upload css */
.mainDivUploadImg {
  width: 100%;
  max-width: 125.2rem !important;
  padding-bottom: 40px;
  background: #ffffff;
  border: 1px solid #ecebec;
  border-radius: 4px;
}

.browseBtn {
  width: 220px;
  height: 40px;
  border: 1px solid #dedfe1;
  border-radius: 3px;
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: var(--fontColorNormal2);
  cursor: pointer;
}

.uploadImgDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  margin-left: 24px;
  margin-right: 24px;
  border: 1px dashed #dedfe1;
  /* background-color: grey; */
  padding: 24px;
}

.imgIconDiv {
  width: 160px;
  height: 160px;
  position: relative;
}

.imgIconDiv > img {
  width: 100%;
  height: 100%;
}

.previewDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.closeIcon {
  position: absolute;
  right: -5px;
  top: -7px;
  cursor: pointer;
}

.imgSizeText {
  color: #121212;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.imgSizeText > span {
  color: #74676e;
}

.error {
  padding-left: 23px;
  color: red;
}

.coverDiv {
  position: absolute;
  display: flex;
  bottom: 9px;
  background: #efefef;
  border-radius: 8px;
  height: 24px;
  width: 152px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  left: 3px;
}
