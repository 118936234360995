.topNavList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-right: 10px;
}

.topNavList > h1 {
  font-weight: var(--fontWeight2) !important;
  font-size: var(--fontSize2) !important;
  line-height: 24px !important;
  color: var(--textColor) !important;
}

.middleDivList {
  height: 55vh;
  overflow: hidden;
  overflow-y: auto;
}

.topNavList > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  color: #121212;
  background: #f4e0eb;
  border-radius: 4px;
  padding: 4px 8px;
}
