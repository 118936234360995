.headerMaindiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  /* position: sticky !important; */
  top: 0;
  filter: drop-shadow(4px 0px 8px rgba(18, 18, 18, 0.12));
  background: var(--bgColor1);
  z-index: 100;
}

.HeaderHeading {
  font-weight: var(--fontWeight1);
  line-height: 24px;
  font-size: var(--fontSize1);
  margin-left: 24px;
  color: var(--textColorActive1);
}

.rightHeaderDiv {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-right: 31px;
}

.switchIconDiv {
  cursor: pointer;
}

.profileDiv {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
}

.rightHeaderDiv > span {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.rightHeaderDiv > span > img {
  width: 100%;
  height: 100%;
}

.profileDiv > p {
  font-weight: 700;
  font-size: var(--fontSize2);
  line-height: 20px;
  color: var(--textColorActive1);
  cursor: pointer;
}
.communityName:hover {
  text-decoration: none !important;
}
.communityName:focus {
  text-decoration: none !important;
}
