.filterCrossIcon {
  position: absolute;
  top: 3%;
  right: 3%;
  cursor: pointer;
}

.weekMain {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px;
}

.weekDiv {
  border: 1px solid var(--textColorActive);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: black;
}

.weekDiv:hover {
  background: var(--bgColorActiveTab);
  color: white;
}

.weekDivActive {
  color: white;
  background: var(--textColorActive);
}

.weekDisabledDays {
  color: black;
  border: 1px solid var(--textColorActive);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn {
  width: 60px;
  height: 32px;
  background: var(--textColorActive);
  border-radius: 3px;
  color: var(--bgColor1);
  margin: 10px;
}

.btn:hover {
  background: var(--bgColorActiveTab);
}

.calenderP {
  margin-top: 10px;
  margin-bottom: 20px;
}

.weekInput {
  width: 100% !important;
  height: 44px;

  background: #ffffff;
  border: 1px solid #dedfe1;
  border-radius: 4px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: relative;
}

.weekInputDisabled {
  background: #fafafa;
}

.calenderIconDiv {
  position: absolute;
  right: 10px;
}

.selectDays {
  color: #c0c2c5;
  font-size: 10px;
  font-weight: 300;
  line-height: 20px;
}

.selectClearDiv {
  color: var(--fontColorActiveTab1);
  font-size: 14px;
  border: 1px solid var(--fontColorActiveTab1);
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 2px;
  width: 120px;
}

.selClearOuterDiv {
  padding-top: 10px;
  user-select: none;
  display: flex;
  gap: 15px;
  justify-content: center;
  /* align-items: center; */
  cursor: pointer;
  /* padding-left: 15px; */
}

.daysChipDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 25px;
  background: var(--bgcolor);
  color: var(--fontColorActiveTab1);
  border-radius: 5px;
}

.daysChipDivTrainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 25px;
  background: var(--bgcolor);
  color: var(--fontColorActiveTab1);
  border-radius: 5px;
  font-size: 12px;
}
