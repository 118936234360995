.openViewModalDiv {
  width: 840px;
}

.viewBookingMainDiv {
  height: 73vh;
  overflow-y: auto;
}

.viewBookingPaddingDiv {
  padding: 0px 50px 20px 50px;
}

.topDiv {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  background-color: rgba(250, 250, 250, 1);
  border-radius: 4px;
  margin-top: 15px;
}

.navDiv {
  margin-left: 29px;
  margin-top: 12px;
  position: sticky;
}
.bookingRow {
  display: flex;
  gap: 127px;
}
.inputDivMain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize3);
  line-height: 20px;
  width: 170px;
  padding: 5px 0px 5px 0px;
}
.inputDiv {
  border-radius: 4px;
  padding-top: 8px;
  font-weight: var(--fontWeight2);
  font-size: 14px;
  line-height: 20px;
  color: var(--fontColorNormal2);
}
.bottomOuterDiv {
  padding: 0px 50px 40px 50px;
}
.bottomDiv {
  border: 1px solid rgba(222, 223, 225, 1);
  border-radius: 4px;
}

.topGreyDiv {
  height: 50px;
  padding: 10px 20px;
  background-color: rgba(250, 250, 250, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardDiv {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 20px;
}
.outerDiv {
  display: flex;
  gap: 15px;
  width: 100%;
}
.serviceImgDiv {
  height: 150px;
  width: 188px;
}
.serviceImgDiv > img {
  height: 100%;
  width: 100%;
}
.bookigDetailsDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

.infoMainDiv {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  gap: 25px;
  align-items: center;
}
.infoDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
}

.infoDiv > div {
  color: rgba(134, 134, 134, 1);
}
.infoRows {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.colorBlack {
  color: rgba(18, 18, 18, 1);
}
.alignmentDiv {
  width: 40%;
}
.cancelledService {
  color: rgba(171, 0, 0, 1);
}
.bookedService {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1rem;
  height: 30px;
  width: 70px;
  color: rgba(62, 184, 103, 1);
  background-color: rgba(236, 255, 242, 1);
}
.bookedForDiv {
  padding: 16px 32px;
  background-color: var(--bgColor2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.infoColDiv {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
