.react-toggle--checked .react-toggle-track {
  background-color: var(--textColorActive) !important ;
}
.react-toggle--checked .react-toggle-thumb {
  left: 18px;
}
.react-toggle-track {
  width: 32px;
  height: 20px;
}
.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked {
  color: white !important;
}
.react-toggle-thumb {
  top: 4.3px;
  left: 3px;
  width: 11px;
  height: 11px;
  border: none;
}
