.mainDivBookingHistory {
  width: 100%;
}

.tblMainDiv {
  /* border: 1px solid black; */
  width: 91vw;
  overflow: auto;
  margin-left: 19px;
  padding: 5px;
  padding-right: 20px;
}

.bookingSearch {
  height: 72px;
  border-radius: 4px;
  background: #fafafa;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  margin-right: 32px;
}

.statusSelectDiv {
  display: flex;
  align-items: center;
  width: 280px;
  /* width: 250px; */
  border-radius: 0px 8px 8px 0px;
  background: #fff;
}

/* .statusSelectDiv select {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
} */

/* .statusSelectDiv>div {
    width: 71px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 8px 0px 0px 8px;
    background: #ECEBED;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.selectTitle {
  width: 71px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px 0px 0px 8px;
  background: #ecebed;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectStatus {
  width: 208px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 0px 8px 8px 0px;
  background: #fff;
  border: none;
  padding: 10px 16px;
  outline: none;
}

.searchDiv {
  width: 256px;
  height: 40px;
  /* border: 1px solid lightgray; */
  display: flex;
  align-items: center;
  margin-right: 10px;
  border-radius: 4px;
  background: #fff;
}

.searchDivInput {
  /* width: 100%; */
  border: none;
  outline: none;
  /* width: 72vw !important; */
  /* border: none !important; */
  background-color: #fff !important;
}

.searchIcon {
  cursor: pointer;
}

.dateDiv {
  display: flex;
  align-items: center;
  gap: 16px;
}

.dateDiv > span {
  width: 54px !important;
  height: 40px !important;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  color: #92215c !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dateRangePicker {
  width: 336px !important;
  /* width: 250px !important; */
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  outline: none !important;
}

.goBtn {
  width: 54px !important;
  height: 40px !important;

  /* flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    color: #92215C !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; */
}

/* table {
    border-collapse: separate !important;
    border-spacing: 0 8px !important;
}

table,
td,
th {
    border: none !important;
} */
::-webkit-scrollbar {
  height: 4px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin-top: 32px;
}

.paginationValuesDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #4d5259 !important;
}

.paginationValuesDiv > p {
  color: #4d5259 !important;
}

.pagination > div {
  display: flex;
  gap: 44px;
}

.paginationIconDiv {
  display: flex;
  gap: 30px;
  color: #4d5259 !important;
  cursor: pointer;
}

.btnDiv {
  display: flex;
  gap: 8px;
}

.btn {
  width: 60px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  color: #121212;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background-color: #fff;
}
