.mainDivEventsDetailsCard {
  margin: 20px;
}

.middleDivEventsDetailsCard {
  border: 1px solid var(--borderColor);
  border-radius: 4px;
}

.basicInfoTopImgEventsDetailsCard {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 30px;
  margin-top: 16px;
  margin-bottom: 4px;
}

.basicInfoTopImgEventsDetailsCard > div {
  width: 16px;
  height: 16px;
}

.basicInfoTopImgEventsDetailsCard > div > img {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}

.basicInfoTopImgEventsDetailsCard h6 {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize2);
  line-height: 24px;
  color: var(--fontColorNormal4) !important;
}

.mainDivBookingNPopular {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.bookingDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 24px;
  height: 58px;
  width: 50%;
  border: 1px solid var(--borderColor);
  border-radius: var(--borderRadius);
  margin-top: 16px;
  margin-bottom: 16px;
}

.bookingDiv > span {
  display: flex;
  align-items: center;
  gap: 12px;
}

.i {
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.i > img {
  width: 100%;
  height: 100%;
}

.bookingNo {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize4);
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;
  color: var(--textColor);
}

.viewAll {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  text-align: right;
  color: var(--textColorActive);
  cursor: pointer;
}

.bookingTitle {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  text-align: right;
  color: var(--titleColor);
}

.addIconDiv {
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.addIconDiv > img {
  width: 100%;
  height: 100%;
}

.generalInfoDivEventsDetailsCard {
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  margin-left: 0px;
}

.generalInfoDivEventsDetailsCard > div > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  letter-spacing: 0.1px;
  /* text-transform: uppercase; */
  text-align: left;
  margin-bottom: 4px;
  color: var(--fontColorNormal3);
  margin-top: 16px;
}

.generalInfoDivEventsDetailsCard > div > div {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: var(--textColor);
  margin-top: 0px;
  text-align: left;
}

/* .specialGuestEventsDetailsCard {
    margin-top: 24px;
    margin-bottom: 24px;
} */

/* .specialGuestEventsDetailsCard p {
    font-weight: var(--fontWeight3);
    font-size: var(--fontSize2);
    line-height: 20px;
    letter-spacing: -0.02em;
    color: var(--textColor);
    margin-left: 58px;
} */

.seeMoreDiv {
  font-size: 12px;
  color: var(--fontColorNormal4);
}

.instructionsEventsDetailsCard {
  margin-bottom: 24px;
  white-space: pre-wrap;
}

.instructionsEventsDetailsCard p {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  letter-spacing: -0.02em;
  color: var(--textColor);
  margin-left: 56px;
  padding-right: 10px;
  word-break: break-word;
}

.buttonEventsDetailsCard {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.statusColorOpen {
  color: #3eb867 !important;
}

.statusColor {
  color: var(--statusClose) !important;
}

.editIconDiv {
  color: #92215c;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}

.availableDays {
  /* word-break: break-all; */
  max-width: 124px;
}

@media screen and (max-width: 1040px) {
  .generalInfoDivEventsDetailsCard {
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    margin-left: 4px;
  }
}
