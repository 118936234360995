.searchbarDivForIcon {
  display: flex;
  flex: 1;
}

.searchDiv {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 24px 80px 10px 24px;
  padding: 10px 0px 10px 10px;
  background: rgba(250, 250, 250, 1);
  border-radius: 4px;
  height: 40px;
}

.searchIcon {
  cursor: none;
}

.searchDivInput {
  width: 100%;
  border: none;
  outline: none;
  background-color: #fafafa !important;
}

.clubOuterDiv {
  display: flex;
  gap: 40px;
  align-items: center;
  margin: 24px 80px 10px 24px;
  padding: 10px 0px 10px 20px;
  background: rgba(250, 250, 250, 1);
  border-radius: 4px;
  height: 40px;
}

.opDiv {
  display: flex;
  gap: 10px;
  align-items: center;
}

.imgDimenDiv {
  height: 30px;
  width: 30px;
}

.noDataMainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noDataDiv {
  /* width: 400px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 80px; */
  /* border: 1px solid rgb(232, 229, 229); */
}

.noDataDiv > h2 {
  color: #121212;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
}

.noDataDiv > p {
  color: #74676e;
  font-size: 16px;
  line-height: 28px;
  margin-top: 16px;
}

.noDataBtn {
  width: 160px;
  height: 40px;
  padding: 10px 20px;
  border-radius: 3px;
  border: 1px solid #92215c !important;
  background: #fff;
  color: #92215c !important;
}

.noDataImgDiv {
  width: 225px;
  height: 200px;
}

.noDataImgDiv > img {
  width: 100%;
  height: 100%;
}
