.creatEventMainDiv {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 24px;
  padding-right: 80px;
}

.btnCreateEvent {
  margin-bottom: 136px;
  border-top: 1px solid #dedfe1;
  padding-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.createBtn {
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize2);
  line-height: 16px;
  color: #ffffff;
  width: 160.77px;
  height: 40px;
  background: var(--textColorActive);
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.discardBtn {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 16px;
  color: var(--textColorActive);
  width: 160.77px;
  height: 40px;
  background: var(--bgColor1);
  border: 1px solid var(--textColorActive);
  border-radius: 3px;
  cursor: pointer;
}
