* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-style: normal;
  font-family: "poppins";
}

html {
  font-size: 62.5% !important;
  /* changes a default 16px font size to 10px */
}

::placeholder {
  font-weight: var(--fontWeight3) !important;
  font-size: 12px !important;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #121212;
}
/* for radio and checkbox color */
input[type="radio"] {
  accent-color: var(--fontColorActiveTab1);
}

input[type="checkbox"] {
  accent-color: var(--fontColorActiveTab1);
}

.ub-bs_13po50p_xpy5ci:not([disabled]):focus {
  box-shadow: none !important;
}

.css-2s90m6-MuiAvatar-root {
  width: 28px !important;
  height: 28px !important;
}

:root {
  --fontWeight3: 400;
  --fontWeight2: 500;
  --fontWeight1: 600;
  --fontSize1: 20px;
  --fontSize2: 16px;
  --fontSize3: 14px;
  --fontSize4: 12px;
  --fontSize5: 10px;

  --fontColorActiveTab3: #ba397e;

  --fontColorNormal1: #594e53;

  --borderColor: #ecebec;
  /* border Img */
  --boderderColor2: #c5c0c7;
  --borderColor3: #dedfe1;
  --borderRadius: 4px;
  --textColor: #121212;
  --fontColorActiveTab1: #690a3c;
  --fontColorActiveTab2: #ac1063;
  --textColorActive: #92215c;
  /* modalTilte */
  --textColorActive1: #591438;
  /*  */
  --titleColor: #9d9699;
  /* textColor */
  --fontColorNormal2: #121212;

  --fontColorNormal3: #9d9699;
  --fontColorNormal4: #74676e;
  --bgcolor: #f8ecf3;
  /* sidebarbgcolor,headerbg */
  --bgColor1: #ffffff;
  /* bookingEvent */
  --bgColor2: #fafafa;
  /*  */
  --bgColorActiveTab: #f4e0eb;
  --tblHeadColor: #767c82;
  --statusClose: #ab0000;
  --statusOpen: #3eb867;
}

.react-responsive-modal-modal {
  border-radius: 4px !important;
  margin: 0px;
  padding: 0px;
  max-width: 1500px;
  min-width: 408px !important;
}

.rs-picker-toggle-wrapper {
  /* width: 562px !important; */
  height: 44px !important;
}

.rs-picker-daterange-header {
  padding: 8px 8px !important;
  font-size: 13px !important;
  display: none !important;
}

/* .rs-picker-toolbar-right {
    position: absolute;
    top: 4px;
    right: 5px !important
} */

.rs-icon {
  color: #121212 !important;
}

.rs-picker-menu {
  z-index: 3000 !important;
}
.rs-picker-popup {
  z-index: 3000 !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-bottom: 12px;
}

.rs-btn:focus {
  border-color: var(--fontColorNormal2) !important;
}

.rs-picker-toggle:hover {
  border-color: var(--fontColorNormal2) !important;
}

.rs-picker-toggle:focus-visible {
  border-color: var(--fontColorNormal2) !important;
}

.rs-picker-menu .rs-picker-toolbar-ranges {
  display: none !important;
}
.rs-picker-popup .rs-picker-toolbar-ranges {
  display: none !important;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: var(--fontColorNormal2) !important;
}

.rs-picker-toggle:focus-visible {
  outline: 3px solid var(--fontColorNormal1) !important;
}

.rs-btn-primary {
  background-color: var(--fontColorActiveTab1) !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: var(--fontColorActiveTab1) !important;
}

.rs-calendar-table-cell-content:hover {
  color: white !important;
  background-color: var(--fontColorActiveTab1) !important;
}

p + p {
  margin-top: 0px;
}

img {
  vertical-align: none;
}

/* tooltip */
.MuiTooltip-tooltip {
  background: #ffffff !important;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize3);
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85) !important;
  padding: 0px !important;
  max-width: 600px !important;
}

.MuiTooltip-tooltip {
  background-color: #ffffff !important;
  padding: 0px !important;
}

.MuiTooltip-popper[data-popper-placement*="left"] .MuiTooltip-tooltip {
  margin-right: 40px !important;
}

.MuiTooltip-arrow {
  color: #ffffff !important;
  /* box-shadow: 5px 10px 8px #ddb8cc !important  */
  /* box-shadow: -5px -5px 20px  #F4E0EB !important; */
}

/* time picker */
.MuiStack-root {
  padding-top: 0px !important;
  outline: none;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 2rem !important;
  height: 2rem !important;
}

/* timepicker */
.css-1xhypcz-MuiStack-root > .MuiTextField-root {
  min-width: 20px !important;
}

/* in financila time picker */
.MuiStack-root > .MuiTextField-root {
  min-width: 50px !important;
}

.Mui-focused {
  border: none !important;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  color: #474d66 !important;
  outline: none !important;
}

/* Calender css */
.MuiButtonBase-root.MuiPickersDay-root {
  border-radius: 4px;
}

.MuiTypography-root.MuiDayCalendar-weekDayLabel {
  color: #121212;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.MuiDayCalendar-header {
  background: var(--accolours-peach-new-peach-01, #fbf6f3);
  margin-bottom: 10px;
}

.MuiButtonBase-root.MuiPickersDay-root {
  border: 1px solid var(--accolours-neutral-neutral-03, #dbdbdb);
}

.MuiButtonBase-root.MuiPickersDay-root:not(.Mui-selected) {
  border: 1px solid var(--accolours-neutral-neutral-03, #dbdbdb);
}

.Mui-selected {
  background-color: var(--fontColorActiveTab1) !important;
}

.MuiButtonBase-root.MuiButton-root {
  color: #000000 !important;
}

.MuiSvgIcon-root {
  color: #121212;
}

/* profile popover */
.ub-tstn-drn_stk3kw {
  top: 89px;
}

/* rsuite date picker  */
/* .rs-calendar-table-cell-content {
    border: 1px solid var(--accolours-neutral-neutral-03, #DBDBDB) !important
}

.rs-calendar-table-header-row {
    background: var(--accolours-peach-new-peach-01, #FBF6F3);
    margin-bottom: 10px !important;
}

.rs-calendar-table-cell-content {
    margin-left: 5px;
} */

@media screen and (max-width: 1040px) {
  :root {
    --fontWeight3: 400;
    --fontWeight2: 500;
    --fontWeight1: 600;
    --fontSize1: 18px;
    --fontSize2: 14px;
    --fontSize3: 12px;
    --fontSize4: 10px;
    --fontSize5: 9px;
  }
}

@media screen and (max-width: 780px) {
  :root {
    --fontWeight3: 400;
    --fontWeight2: 500;
    --fontWeight1: 600;
    --fontSize1: 16px;
    --fontSize2: 13px;
    --fontSize3: 11px;
    --fontSize4: 9px;
    --fontSize5: 8px;
  }
}

/* viewMemberToolTip */
.ub-tstn-pty_9dt9lq {
  left: 978px;
  top: 324px;
  z-index: 1000 !important;
}
.basic-single {
  font-size: var(--fontSize4) !important;
  font-weight: var(--fontWeight3) !important;
  height: 100% !important;
  letter-spacing: -0.02em !important;
  line-height: 16px !important;
  width: 100% !important;
}
.select__indicator-separator {
  display: none !important;
}

.select__menu {
  z-index: 4 !important;
}
.select__menu > div {
  max-height: 200px !important;
  overflow-y: auto !important;
}
.rs-input-group.rs-input-group-inside {
  height: 44px !important;
}
