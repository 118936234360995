.displayFlexDiv {
  margin: 10px;
  display: flex;
  gap: 20px;
  align-items: flex-end;
  justify-content: flex-start;
}

/* .displayFlexDivRowTwo {
  display: flex;
  justify-content: space-between;
  gap: 20px;
} */

.leftDiv {
  margin: 10px 0px 0px 24px;
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  align-items: center;
}

.todayDiv {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.todayDiv:hover {
  color: #92215c;
}

.leftRightArrowBtns {
  display: flex;
  gap: 20px;
}

.leftRightArrowBtns > span > img:hover {
  color: #92215c;
  cursor: pointer;
}

.chipsDiv {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.displayFlexFiltersDiv {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
}

.allfilterdiv {
  display: flex;
  align-items: center;
  /* border: 1px solid #e1e1e1; */
  border-radius: 4px;
  width: 150px;
  z-index: 7;
}

.allfilterdiv > label {
  width: 60px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e1e1e1;
}
.allfilterdiv > input:focus {
  outline: none !important;
  border: transparent !important;
}
.allfilterdiv > input {
  outline: transparent !important;
}
.allfilterdiv > input:focus {
  outline: none;
  border: transparent;
}
.allfilterdiv > input {
  border: transparent;
  outline: transparent;
}

.allfilterdiv > .rmsc {
  width: 170px;
}

.dateRangePicker {
  height: 40px;
  /* width: 230px; */
  outline: none !important;
  border: transparent !important;
}
.rs-picker-daterange .rs-picker-toggle input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

.filterClearDiv {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #c5c1c3;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize4);
  line-height: 16px;
  color: #73696e;
  cursor: pointer;
}
.filterDisplayNav {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chipsDiv > .profDiv,
.chipsDiv > .servDiv {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: var(--bgcolor);
  border-radius: 4px;
  cursor: pointer;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize4);
  line-height: 16px;
  color: var(--fontColorActiveTab2);
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-bottom: 0px;
}
