.mainDivEventsDetails {
  width: calc(100% - 42.8rem);
  border: 1px solid var(--borderColor);
  border-radius: 4px;
  margin-right: 84px;
  margin-left: 12px;
}

.midDivEventsDetails {
  overflow: hidden;
  overflow-y: auto;
  height: 50vh;
}

.topDivEventsDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  left: 1px;
  top: 1px;
  background: var(--bgcolor);
  padding-left: 32px;
  padding-right: 32px;
}

.ctaDiv {
  display: flex;
  align-items: center;
  justify-self: center;
  gap: 14px;
  cursor: pointer;
}

.topDivImgEventsDetails {
  width: 40px;
  height: 40px;
}

.topDivImgEventsDetails > img {
  height: 100%;
  width: 100%;
}

.topDivEventsDetails > span > h1 {
  font-weight: var(--fontWeight1) !important;
  font-size: var(--fontSize1) !important;
  line-height: 24px !important;
  margin-bottom: 2px !important;
  color: var(--textColorActive1);
}

.topDivEventsDetails > span > p {
  margin: 0;
  padding: 0;
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize3) !important;
  line-height: 20px !important;
  text-align: start;
  color: var(--textColor);
}

.noDataToShow {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.hamsburgerDiv > div > p {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize3);
  line-height: 20px;
}

.popoverDiv {
  padding: 18px 55px 18px 28px;
  /* border: 1px solid black; */
  color: var(--fontColorNormal4);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 4px;
  gap: 12px;
  cursor: pointer;
}

.popoverDivActive {
  color: var(--textColorActive1) !important;
  background: var(--bgColorActiveTab);
}

/* .hamsburgerDiv>div {
    padding: 18px 55px 18px 28px;
    color: var(--fontColorNormal4);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 4px;
    gap: 12px;
    cursor: pointer;
}

.hamsburgerDiv>div:hover {
    color: var(--textColorActive1) !important;
    background: var(--bgColorActiveTab);
} */

.hamsburgerImgDiv {
  width: 16px;
  height: 16px;
  /* border: 1px solid green; */
  display: block;
  border-radius: 4px;
}

.hamsburgerImgDiv > img {
  width: 100%;
  height: 100%;
}

.tooltipChangeStatus {
  box-shadow: 5px 10px 8px #f4e0eb;
}

.tooltipChangeStatus > p {
  padding-left: 24px;
  padding-top: 17px;
  padding-right: 96px;
  padding-bottom: 17px;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize3);
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
}

.tooltipChangeStatus > p:hover {
  /* background: #F4E0EB; */
  color: var(--textColorActive);
}
.status {
  background: var(--bgColorActiveTab) !important;
  color: var(--textColorActive) !important;
}

.noDataToShow {
}
