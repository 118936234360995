.cardOuterDiv {
  width: 362px;
  border: 1px solid rgba(222, 223, 225, 1);
  padding: 12px;
  border-radius: 4px;
  height: 145px;
}

.topDiv {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 15px;
}

.cardData {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 17px;
  font-weight: 500;
  color: rgba(18, 18, 18, 1);
}

.bottomDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rowDataDiv {
  display: flex;
  gap: 10px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(18, 18, 18, 1);
}
