.buttonSmall {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 10px 20px; */
    gap: 8px;
    width: 160px;
    height: 40px;
    border: 1px solid var(--textColorActive);
    border-radius: 3px;
    flex: none;
    order: 1;
    flex-grow: 0;
    font-weight: var(--fontWeight1);
    font-size: 13px;
    line-height: 20px;
    text-align: right;
    color:  var(--textColorActive);
}

.buttonSmall:hover {
    cursor: pointer;
}