.proRightMainDiv {
  width: calc(100% - 41rem);
  display: flex;
  flex-direction: column;
}

.mainDivServicesAssigned {
  height: 23vh;
  border: 1px solid var(--borderColor);
  border-radius: 4px;
  margin-right: 84px;
  margin-left: 12px;
  margin-bottom: 13px;
}

.mainDivProfessionalBookings {
  height: calc(100% - 23vh);
  border: 1px solid var(--borderColor);
  border-radius: 4px;
  margin-right: 84px;
  margin-left: 12px;
}

/* Services assigned CSS */

.horizScrollDiv {
  margin-left: 14px;
}

.horizScrollDiv > div:first-child {
  margin-bottom: 10px;
}

.topServicesFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.topServicesFlex > img {
  padding-right: 12px;
}

.servicesAssignedTextDiv {
  padding: 13px 0px;
  padding-bottom: 30px;
}

.servicesAssignedMainDiv {
  display: flex;
  overflow-x: auto;
  width: 51vw;
  padding-bottom: 12px;
}

.serviceAssignedCard {
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 150px;
  max-width: 200px;
  height: 65px;
  border-radius: 4px;
  border: 1px solid var(--borderColor);
  margin-right: 12px;
  padding: 10px 10px;
}

/* Professional Bookings */
