.customModal {
  width: 700px !important ;
}
.mainDiv {
  margin-bottom: 32px;
}
.title {
  color: var(--textColorActive1);
  font-size: var(--fontSize1);
  font-style: normal;
  font-weight: var(--fontWeight1);
  line-height: 24px;
  text-align: center;
  padding-bottom: 40px;
  margin-top: 32px;
}
.dynamicDiv {
  /* height: 200px;
  overflow-y: auto; */
}
.displayFlexDiv {
  background: #f9f9f9;
  padding-left: 16px;
  padding-right: 16px;
}
.displayFlexDiv > div {
  display: flex;
  align-items: start;

  gap: 20px;
  padding-top: 16px;
}
.displayFlexDivLeft {
  width: 50%;
  /* border: 1px solid red; */
}
.displayFlexDivRight {
  width: 50%;
  /* border: 1px solid red; */
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
}
.addFieldDiv {
  width: 40%;
}
.fieldTypeDiv {
  width: 100%;
}
.labelFont {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize2) !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2) !important;
}

.star {
  color: var(--statusClose);
}
.inputText {
  margin-top: 12px !important;
  width: 100% !important;
  height: 40px !important;
}
.divOutline {
  margin-top: 24px;
}

.btnDiv {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 32px;
  gap: 24px;
  padding-right: 60px;
}
.addBtnSecondry {
  color: #448eb9;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  background: #fafafa;
  width: 140px;
  padding: 10px;

  border-radius: 5px;
  cursor: pointer;
}
.btnDiv {
  display: flex;
  justify-content: end;
}
.scrollDiv {
  height: 50vh;
  overflow-y: auto;
  padding-right: 60px;
  padding-left: 60px;
}
.btnDivExisting {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}
.cursor {
  cursor: pointer;
}
