.buttonsolidmd {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 10px 20px 10px 20px;
  background: var( --textColorActive);
  border-radius: 3px;
  font-weight: var(--fontWeight1);
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  outline: none;
  cursor: pointer;
}

.buttonsolidmd :focus {
  outline: none;
}

button {
  outline: none;
}

button:focus {
  outline: none;
}