.mainDivBookEventModal {
  border-radius: 8px;
  width: 765px;
}

.mainDivBookEventModal > div {
  padding-left: 32px;
  padding-right: 32px;
}

.scrollDiv {
  height: 50vh;
  overflow-y: auto;
}

.headingBookEventModal {
  margin-top: 32px;
  color: var(--textColorActive1);
  font-weight: var(--fontWeight2) !important;
  font-size: 24px !important;
  line-height: 28px;
  text-align: center;
  margin-bottom: 16px;
}

.LLabel {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize2) !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2);
  font-weight: var(--fontWeight3);
}

.flexDivBookEventDetail {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.mgbottominputSB {
  margin-bottom: 24px;
  border: 1px solid #ecebec !important;
  border-radius: 4px;
  margin-top: 12px;
}

::placeholder {
  font-style: normal !important;
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize4) !important;
  line-height: 16px !important;
  color: #b7b1b4 !important;
  margin-left: 8px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.LLabelBookSeat {
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize3);
  line-height: 20px;
  color: var(--textColor);
}

.btnDivBookEventDetail {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.bookUnitDiv {
  display: flex;
  align-items: center;
  border: 1px solid var(--bgColor2);
  width: 534px;
  height: 44px;
  background: var(--bgColor2);
  border-radius: 4px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 10px;
}

.counterEventModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 10px;

  width: 32px;
  height: 32px;
  color: #92215c;
  background: #ffffff;
  border: 0.5px solid #92215c;
  border-radius: 1px;
}

.counterMinusEventModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px;
  gap: 10px;

  width: 24px;
  height: 24px;
  color: #92215c;

  border: 0.5px solid #92215c;
  border-radius: 50%;
}

.selectNoSeats > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: #000000;
}

.memberUnitDiv {
  display: flex;
  align-items: center;
  width: 534px;
  height: 44px;
  background: #fafafa;
  border-radius: 4px;
  margin-bottom: 40px;
  cursor: pointer;
}

.memberUnitSelect {
  width: 274px;
  height: 36px;
  background: #fafafa;
  border-radius: 4px;
}

.memberUnitSelect > p {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #74676e;
  padding: 8px 16px;
}

.memberUnitSelectActive > p {
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize2);
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #92215c;
  padding: 8px 16px;
}

.memberUnitSelectActive {
  width: 274px;
  height: 36px;
  background: #f8ecf3;
  border-radius: 4px;
}

.absoluteDiv {
  position: relative;
}

.nameDiv {
  position: absolute;
  top: 47px;
  right: 13px;
  z-index: 3;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize3);
  line-height: 18px;
  color: var(--fontColorNormal4);
}

.timeDiv {
  display: flex;
  flex-direction: column;
  /* margin-top: 32px; */
  gap: 16px;
}

.timerInput {
  margin-top: 14px;
  /* width: 254px; */
  margin-bottom: 24px;
}

.slotDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  width: 630px;
  padding-top: 12px;
  padding-bottom: 10px;
}

.slotDiv > p {
  background: #ffffff;
  border: 1px solid #ecebed;
  border-radius: 16px;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize4);
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #868686;
  padding: 8px 13px;
  /* margin-top: 12px; */
  cursor: pointer;
  width: 140px;
}

.slotDivService {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  width: 630px;
  padding-top: 12px;
  padding-bottom: 10px;
}

.slotDivService > p {
  background: #ffffff;
  border: 1px solid #ecebed;
  border-radius: 16px;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize4);
  line-height: 14px;
  text-align: left;
  letter-spacing: -0.02em;
  color: #868686;
  padding: 4px 15px;
  /* margin-top: 12px; */
  cursor: pointer;
  width: 140px;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
}

.mobileGenderDiv {
  display: flex;
  align-items: center;
  gap: 26px;
  width: 100%;
}

.mobileGenderDiv > div {
  width: 50%;
}

.subHeading {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: var(--titleColor);
  text-align: center;
  margin-bottom: 15px;
}

.bookingSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-radius: 4px;
  /* margin-bottom: 32px; */
  margin-bottom: 20px;
  /* position: absolute; */
}

.bookingSearch > input {
  background-color: #fafafa;
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  padding-left: 12px;
}

.searchIcon {
  width: 32px;
  height: 40px;
}

.searchIcon > img {
  width: 100%;
  height: 100%;
}

.timeColor {
  color: var(--fontColorNormal2) !important;
}

.timeSelected {
  color: #92215c !important;
  background-color: #f4e0eb !important;
  border: 1px solid #92215c !important;
}

.menu {
  margin-top: 98px;
  width: 90%;
  background: #ffffff;
  position: absolute;
  top: 60px;
  right: 32px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 1;
}

.menu.active {
  z-index: 1;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}

.menu li:hover {
  background: #f4e0eb;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

.amountCard {
  display: flex;
  flex-direction: column;
  padding: 24px 15px 24px 16px;
  justify-content: space-between;
  align-items: space-between;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fafafa;
  margin-top: 22px;
}

.flexJustify {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.amountText {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: end;
}

.discountCont {
  display: flex;
  gap: 4px;
}

.originalCost {
  color: #bebebe;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: -0.24px;
  text-decoration: line-through;
}

.discount {
  color: #ab0000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: -0.24px;
}
.nameInputCont {
  width: 620px;
}

/* trainer implementation css */

.trainerContainerDiv {
  display: flex;
  gap: 16px;
  overflow-x: scroll;
  padding-top: 12px;
  padding-bottom: 24px;
}

.trainerContainerDiv::-webkit-scrollbar {
  display: none;
}

.inputDiv {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.trainerLabel {
  font-weight: 500;
}

.trainerDiv {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 64px;
}
.imageDiv {
  height: 64px;
  width: 64px;
  flex-shrink: 0;
  background: #f5ecf9;
  position: relative;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkBox {
  position: absolute;
  top: -10px;
  right: -4px;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  fill: #fff;
  stroke-width: 1px;
  stroke: #868686;
}
.trainerName {
  color: var(--textColor);
  text-align: center;
  word-break: break-word;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.2px;
}

.inputDivTC {
  display: flex;
  gap: 10px;
  margin-bottom: 22px;
}
