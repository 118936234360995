.title {
  color: var(--textColorActive1);
  font-size: var(--fontSize1);
  font-style: normal;
  font-weight: var(--fontWeight1);
  line-height: 24px;
  text-align: center;
  padding-bottom: 40px;
  margin-top: 32px;
}

.mainDiv {
  padding-left: 60px;
  padding-right: 60px;
}

.labelFont {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize2) !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2) !important;
}

.star {
  color: var(--statusClose);
}

.inputText {
  margin-top: 12px !important;
  width: 100% !important;
  height: 44px !important;
}

.btnDiv {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 32px;
  gap: 24px;
}

.InputDiv1 {
  margin-bottom: 40px;
  margin-top: 24px;
}

.labelFont {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2) !important;
  line-height: 20px;
  color: var(--fontColorNormal2) !important;
  margin-bottom: 12px;
}

.divOutline {
  margin-top: 24px;
}
.closeIcon {
  position: absolute;
  right: -5px;
  top: -7px;
  cursor: pointer;
}

.browseBtn {
  width: 100px;
  height: 32px;
  border: 1px solid #dedfe1;
  border-radius: 3px;
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize4);
  line-height: 20px;
  color: #121212;
  cursor: pointer;
}

.error {
  padding-left: 23px;
  color: red;
  position: absolute;
  bottom: -15px;
  left: -2px;
}

.suspendDiv {
  display: flex;
  /* align-items: center; */
  border-radius: 4px;
  border: 1px solid #dedfe1;
  background: #fff;
  justify-content: space-between;
  padding: 16px;
  margin-top: 32px;
}

.suspendTitle {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.radioMainDiv {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 10px;
  width: 100% !important;
}

.radioDiv {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
}

.radioDivActive {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
  color: var(--fontColorActiveTab1);
}

.radioLabel {
  font-style: normal;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: #666666;
}
.seatAndWaitingDiv {
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
