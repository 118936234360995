.mainDivBookModal {
  border-radius: 8px;
  width: 765px;
}

.mainDivBookModal > div {
  padding-left: 32px;
  padding-right: 32px;
}

.scrollDiv {
  height: 48vh;
  overflow-y: auto;
  padding-right: 10px;
  margin-bottom: 10px;
}

.headingBookModal {
  margin-top: 32px;
  color: var(--textColorActive1);
  font-weight: var(--fontWeight2) !important;
  font-size: 24px !important;
  line-height: 28px;
  text-align: center;
  margin-bottom: 16px;
}

.subHeading {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: var(--titleColor);
  text-align: center;
  margin-bottom: 15px;
  /* padding-left: 92px;
    padding-right: 92px; */
}

.bookingSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-radius: 4px;
  /* margin-bottom: 32px; */
  margin-bottom: 20px;
  /* position: absolute; */
  min-width: 534px;
}

.bookingSearch > input {
  background-color: #fafafa;
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  padding-left: 12px;
}

.searchIcon {
  width: 32px;
  height: 40px;
}

.searchIcon > img {
  width: 100%;
  height: 100%;
}

.menu {
  margin-top: 98px;
  width: 82%;
  background: #ffffff;
  position: absolute;
  top: 60px;
  right: 59px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 1;
}

.menu.active {
  z-index: 1;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}

.menu li:hover {
  background: #f4e0eb;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

.LLabel {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize2) !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2);
  font-weight: var(--fontWeight3);
}

.mgbottominputSB {
  margin-bottom: 24px;
  border: 1px solid #ecebec !important;
  border-radius: 4px;
  margin-top: 12px;
}
.mgbottominputSBtext {
  /* margin-bottom: 24px; */
  border: 1px solid #ecebec !important;
  border-radius: 4px;
  margin-top: 12px;
}

::placeholder {
  font-style: normal !important;
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize4) !important;
  line-height: 16px !important;
  color: #b7b1b4 !important;
  margin-left: 8px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mobileGenderDiv {
  display: flex;
  align-items: center;
  gap: 26px;
  width: 100%;
}

.mobileGenderDiv > div {
  width: 50%;
}

.labelFont {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize2) !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2) !important;
}

.radioLabel {
  font-style: normal;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: #666666;
}

.radioMainDiv {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
  width: 100% !important;
  margin-bottom: 24px;
}

.radioDiv {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
}

.radioDivActive {
  display: flex;
  align-items: center;
  gap: 12px;
  /* height: 44px; */
  color: var(--fontColorActiveTab1);
}

.radioDivActive > label {
  color: var(--fontColorActiveTab1);
}

.inputDivTC {
  display: flex;
  gap: 10px;
  margin-bottom: 22px;
}

.timerInput {
  margin-top: 12px;
  /* width: 254px; */
  margin-bottom: 24px;
}

.btnDiv {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 24px;
}

.manualDiv {
  max-width: 100%;
}

.amountCard {
  display: flex;
  padding: 24px 15px 24px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fafafa;
  margin-top: 22px;
}

.amountText {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: end;
}

.discountCont {
  display: flex;
  gap: 4px;
}

.originalCost {
  color: #bebebe;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: -0.24px;
  text-decoration: line-through;
}

.discount {
  color: #ab0000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: -0.24px;
}

.nameInputCont {
  width: 620px;
}
.textCountDiv {
  text-align: right;
}
.maxCountText {
  /* display: flex; */
  width: 75%;
  margin-bottom: 14px;
}
.mainOuterLimitDiv {
  display: flex;
  color: red;
}
.maxLimitCountNumber {
  width: 25%;
  text-align: right;
}
.unavailableDateDiv {
  color: #ab0000;
  text-align: center;
  padding-top: 10px;
}
