.routingMainContainer {
  display: flex !important;
  width: 100% !important;
}

.routingMainContainer > div {
  width: 100%;
  position: relative;
}

.routingSubContainer {
  flex-grow: 1 !important;
}

@media screen and (max-width: 1266px) {
  .routingMainContainer > div {
    position: relative;
    width: calc(100% - -507px);
  }
}
