.heading {
  color: var(--textColorActive1);
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 32px;
  margin-bottom: 12px;
  text-align: center;
}
.subHeading {
  color: var(--anacity-neutral-n-200, #73696e);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-bottom: 10px;
  padding-left: 180px;
  padding-right: 180px;
}
.counterModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 10px;

  width: 32px;
  height: 32px;
  color: var(--textColorActive);
  background: #ffffff;
  border: 0.5px solid var(--textColorActive);
  border-radius: 1px;
}

.counterMinus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px;
  gap: 10px;
  width: 24px;
  height: 24px;
  /* color: var(--textColorActive);
  border: 0.5px solid var(--textColorActive); */
  border-radius: 50%;
}
.bookedMainDiv {
  border-bottom: 1px solid #ecebed;
  padding-bottom: 20px;
}
.flexDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.bookedDivTitle {
  display: flex;
  gap: 5px;
}
.textLabel {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 24px;
}
.colorText {
  color: #ab0000;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}
.seatCancelledMainDiv {
  border-bottom: 1px solid #ecebed;
  padding-bottom: 20px;
}
.reasonDiv {
  border-radius: 4px;
  background: #fafafa;
  height: 230px;
  padding-left: 56px;
  padding-right: 56px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 40px;
}
.labelFont {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.inputTextArea {
  width: 100% !important;
  height: 152px !important;
  margin-top: 12px;
  border-radius: 4px;
  border: 1px solid #dedfe1;
}

.star {
  color: var(--statusClose);
}
.btnDiv {
  border-top: 1px solid var(--bgColor2);
  display: flex;
  gap: 24px;
  padding-top: 10px;
  margin-top: 18px;
  margin-bottom: 32px !important;
  justify-content: center;
}
