.sidebarMainDiv {
  box-sizing: border-box;
  z-index: 10;
  position: sticky !important;
  width: 96px !important;
  left: 0px !important;
  top: 0px;
  background: #fafafa;
  border-right: 1px solid #edebec;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px !important;
  padding-bottom: 20px;
  max-height: 100vh;
}

.sidebarTopDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebarTopDivTop {
  height: 100px;
}
.sidebarTopDivTop2 {
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
}
.sidebarTopDivTop2 > img {
  height: 64px;
  width: 100%;
  padding-top: 20px !important;
}

.sidebarTopDivBottom > div {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 64px;
  border: transparent;
  background-color: var(--bgColor1);
  margin-bottom: 5px;
  gap: 8px !important;
  padding: 4px;
}
.sidebarTopDivBottom2 > div {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 64px;
  border: transparent;
  background-color: var(--bgColor1);
  margin-bottom: 5px;
  gap: 8px !important;
  padding: 4px;
}

.sidebarTopDivBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  max-height: calc(100vh - 117px);
  padding-bottom: 24px;
}
.sidebarTopDivBottom2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  max-height: calc(100vh - 80px);
  padding-bottom: 24px;
}
.sidebarTopDivBottom::-webkit-scrollbar {
  display: none;
}
.sidebarTopDivBottom2::-webkit-scrollbar {
  display: none;
}

.sidebarBottomDiv div {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border: transparent;
  margin-bottom: 5px;
}

.sidebarBottomDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 66px;
}

.hrlinemain {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: flex-end;
}

.hrline {
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #edebec;
}

.sideBarImg {
  margin-top: 30px;
  margin-bottom: 67px;
}

.logoutImg {
  margin-bottom: 66px;
}

.sidebarBottomDiv > div {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border: transparent;
  gap: 2;
}

.activetab {
  background: var(--bgColorActiveTab) !important;
  border-radius: 4px;
}

.inactivetab {
  transition: 0.2s;
}

.inactivetab:hover {
  background-color: #e6e2e2;
  border-radius: 4px;
}
