.customModal {
  width: 1000px !important ;
}
.navDiv {
  min-width: 900px;
}
.heading {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
}
.mainDiv {
  padding-left: 32px;
  padding-right: 32px;
  height: 350px;
  overflow-y: scroll;
}
.flexDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fieldsDiv {
  width: 45%;
  margin-top: 24px;
}
.label {
  color: #121212;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.dropDownDiv {
  margin-top: 12px;
}
.dynamicFormDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
}
.fieldDiv {
  width: 45%;
  margin-top: 10px;
}
.label {
  color: #121212;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.star {
  color: var(--statusClose);
}
.inputText {
  margin-top: 12px;
  width: 100% !important;
  height: 44px !important;
}
.inputTextArea {
  width: 100% !important;
  height: 152px !important;
  margin-top: 12px;
}
.dynamicRadioDiv {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}
.radioLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #666666;
}
.radioDiv {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
}

.radioDivActive {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
  color: var(--fontColorActiveTab1);
}
.radioDivActive > label {
  color: var(--fontColorActiveTab1);
}
.dateRangeDiv {
  display: flex;
  gap: 10px;
  margin-top: 12px;
}
.dateDiv {
  margin-top: 12px;
}

.ImgUploadMainDiv {
  width: 100%;
  border: 3px dashed var(--borderColor3);
  border-radius: 4px;

  cursor: pointer;
  margin-top: 12px;
}

.ImageDiv {
  display: flex;
  align-items: center;
  gap: 32px;
}

.imgUploadDiv1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 38px;
}
.uploadImgDiv {
  position: relative !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
}
.previewDiv {
  display: flex;
  align-items: center;
  gap: 10px;
}
.imgIconDiv {
  width: 50px;
  height: 50px;
  position: relative;
}

.imgIconDiv > img {
  width: 100%;
  height: 100%;
}
.closeIcon {
  position: absolute;
  right: -5px;
  top: -7px;
  cursor: pointer;
}
.browseBtn {
  width: 100px;
  height: 32px;
  border: 1px solid #dedfe1;
  border-radius: 3px;
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize4);
  line-height: 20px;
  color: #121212;
  cursor: pointer;
}
.error {
  padding-left: 23px;
  color: red;
  position: absolute;
  bottom: -15px;
  left: -2px;
}
.btnDiv {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  gap: 100px;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
}
.rupee {
  color: #121212;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.logoDiv {
  height: 70vh;
}
.dateDiv > div > div > div {
  width: 100%;
}
