.title {
  color: var(--textColorActive1) !important;
  font-size: var(--fontSize1);
  font-style: normal;
  font-weight: var(--fontWeight1);
  line-height: 24px;
  text-align: center;
  padding-left: 164px;
  padding-right: 164px;
}

.text {
  color: #73696e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-top: 12px;
}

.outerDivBox {
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
  width: 650px;
}

.mainDiv {
  padding-top: 32px;
  padding-bottom: 32px;
  box-sizing: border-box;
  width: 650px;
}

.labelFont {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.fieldsDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #dedfe1;
  padding: 20px;
  margin-top: 15px;
  color: #121212;
}

.fieldsDiv:hover {
  background: #ecebec;
}

.star {
  color: var(--statusClose);
}

.suspendDiv {
  display: flex;
  border-radius: 4px;
  border: 1px solid #dedfe1;
  background: #fff;
  justify-content: space-between;
  padding: 16px;
  margin-top: 32px;
}

.suspendTitle {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.disclaimer {
  color: #73696e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 8px;
}

.btnDiv {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 55px;
}
.radioDivActive {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
  color: var(--fontColorActiveTab1);
}

.radioDivActive > label {
  color: var(--fontColorActiveTab1);
}

.radioDiv {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
}

.star {
  color: var(--statusClose);
}

.displayFlexDiv {
  display: flex;
  align-items: center;
  margin-top: 32px;
  gap: 15px;
  justify-content: space-between;
}

.displayFlexDiv > div {
  width: 100%;
}

.displayFlexDivPadding {
  display: flex;
  align-items: center;
  margin-top: 32px;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
}

.displayFlexDivPadding > div {
  width: 100%;
}

.radioMainDiv {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
  width: 100%;
  flex-wrap: wrap;
}
.inputText {
  margin-top: 12px;
  width: 100% !important;
  height: 44px !important;
}

.scrollOuterDiv {
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  overflow-y: scroll;
  height: 45vh;
}

.eventBookMemberBtn {
  height: 44px;
  color: #92215c;
  padding: 4px 4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 16px;
  border: 0px solid #663a7e;
  background: #fafafa;
}
.eventBtnNonMember {
  width: 100%;
  color: #74676e;
  height: 36px;
  letter-spacing: -0.32px;
  background-color: #fafafa;
}

.eventBtnMember {
  width: 100%;
  height: 36px;
  font-size: 16px;
  border-radius: 4px;
  background: #f8ecf3;
}
