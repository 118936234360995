.TimeRangePickerCustom {
  width: 50vw;
  height: 130px;
  display: flex;
  padding-bottom: 10px;
  padding-right: 0px;
  box-sizing: border-box;
}

.TimeRangePickerCustomContainer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  overflow: auto;
  background-color: white;
  padding-left: 15px;
  padding-right: 15px;
}
.TimeRangePickerCustomContainer::-webkit-scrollbar {
  display: block !important;
  width: 5px;
  height: 5px;
}

.TimeRangePickerCustomContainer::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 2.5px;
}
.TimeRangePickerCustomContainer::-webkit-scrollbar-track {
  border-radius: 2.5px;
  background: #ededed;
}
.block {
  display: flex;
  width: 100%;
  border-left: 1px solid #dbdbdb;
  height: 90%;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid #dbdbdb;
  position: relative;
}

.blockNew {
  display: flex;
  width: 100%;
  height: 90%;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid #dbdbdb;
  position: relative;
}
.blockNew::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1px; /* Border width */
  height: 50%; /* Half the height of the div */
  background-color: #dbdbdb; /* Border color */
}

.divContainer {
  height: 90%;
  width: 40px;
  min-width: 40px;
  display: flex;
  flex-direction: column;
}
.timeDiv {
  height: 15%;
  width: 100%;
  font-size: 8px;
  position: relative;
  left: -15px;
  padding-bottom: 5px;
  font-weight: 500;
}
.selected {
  background-color: #36ab6c;
  border: 1px solid #36AB6C;
}
.disabled {
  background-color: #dbdbdb;
}
.availableTimeSlotsMain {
  width: 100%;
  box-sizing: border-box;
}
.availableTimeSlots {
  width: 100%;

  padding-bottom: 15px;

  display: flex;
  gap: 10px;
  box-sizing: border-box;
  overflow: auto;
}
.availableTimeSlots::-webkit-scrollbar {
  display: none;
}

.availableTimeSlots > div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background-color: #dbdbdb;
  padding: 8px 10px;
  white-space: nowrap;
  border-radius: 15px;
  min-width: 40px;
  display: flex;
  justify-content: center;
}
.selectedCTA {
  background-color: #f5ecf9 !important;
  color: #663879;
}
.label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: "poppins";
  color: #121212;
}
.leftBorderBlock {
  border-left: 2px solid #36ab6c;
}
.mandatory {
  color: var(--fontColorRequired);
}
