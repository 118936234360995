.mainSingleItemDiv {
  width: 100%;
  height: 120px;
  border-radius: 4px;
  border: 1px solid var(--borderColor3);
}

.greyScaleDiv {
  /* width: 100%;
  height: 100px;
  border-radius: 4px;
  border: 1px solid var(--borderColor3); */
  filter: grayscale(70%);
  background-color: #dedfe1;
}

.mainSingleItemPaddingDiv {
  padding: 12px 16px;
  height: 100%;
  display: flex;
  gap: 24px;
}

.imgDiv {
  height: 92px;
  width: 76px;
  border: 1px solid var(--borderColor3);
}

.itemDetailsDiv {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.itemHeadDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.boldTextDiv {
  font-weight: 600;
}
.itemNameDiv {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; /* 125% */
}
.descriptionDiv {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.toggleDiv {
  display: flex;
  width: 125px;
  padding: 5px 5px;
  border-radius: 4px;
  gap: 12px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  background-color: rgba(250, 250, 250, 1);
}

.availableColor {
  color: rgba(62, 184, 103, 1);
}

.itemBodyDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
.actionParentDiv {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.counterBox {
  display: flex;
  padding: 4px;
  width: 32px;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: 1px solid var(--borderColor3);
}

.counterDiv {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 12px;
}

/* ADD REMOVE ITEM CSS */

.itemNo {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #92215c;
  border-radius: 4px;
  width: 32px;
  height: 32px;
}
.itemNo > p {
  color: #591438;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
}
.buttonDiv {
  height: 16px;
  width: 16px;
  color: var(--BaseDarkColor);
  border: 1px solid var(--BaseDarkColor);
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.orderDivMain {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.orderMainCounterDiv {
  display: flex;
  gap: 21px;
  align-items: center;
  justify-content: center;
}
.orderCustom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.customizeDiv {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: var(--BaseDarkColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.unavailableItem {
  color: #9d9699;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.24px;
}
