.creatEventMainDiv {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 24px;
  padding-right: 80px;
}

.mainDiv {
  padding-bottom: 40px;
  background: var(--bgColor1);
  border: 1px solid var(--borderColor);
  border-radius: 4px;
}

.titleDiv {
  background: var(--bgcolor) !important;
  border-radius: 4px 4px 0px 0px;
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 4px;
}

.brMargin {
  margin-top: 12px;
}

.titleDiv > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize2) !important;
  line-height: 20px;
  color: var(--textColorActive1) !important;
}

.labelFont {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize2) !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2) !important;
}

.timePickerMainDiv {
  display: flex;
  gap: 4.8rem;
}

.star {
  color: var(--statusClose);
}

.displayFlexDiv {
  display: flex;
  gap: 56px;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
  justify-content: space-between;
}

.displayFlexDiv > div {
  width: 47%;
}

.inputText {
  margin-top: 12px !important;
  width: 100% !important;
  height: 44px !important;
}

.inputTextArea {
  width: 56px;
  height: 152px;
  margin-top: 12px;
}

.inputTextInputArea {
  width: 100% !important;
  height: 152px !important;
}

.radioLabel {
  font-style: normal;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: #666666;
}

.radioDiv {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
}

.radioDivActive {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
  color: var(--fontColorActiveTab1);
}

.radioDivActive > label {
  color: var(--fontColorActiveTab1);
}

.radioMainDiv {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
  width: 100% !important;
}

.ImgUploadGuestDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  margin-top: 12px;
  position: relative;
}

.guestAdd {
  position: absolute;
  left: 470px;
  top: 12px;
  cursor: pointer;
}

.cancellationAllowedflexDiv > div {
  width: 50% !important;
}

.cancellationAllowedDiv {
  border: 1px solid var(--borderColor);
  border-radius: 4px;
  margin-left: 24px;
  margin-right: 24px;
  /* width: 1204px; */
  margin-top: 16px;
  padding-bottom: 15px;
}

.cancellationAllowedflexDiv {
  display: flex;
  gap: 80px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
  position: relative;
}

.cancellationAllowedBtn {
  background: var(--bgColor2);
  border-radius: 0px 0px 4px 4px;
  padding: 16px 300px;
  width: 100%;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: var(--textColorActive);
  border: none;
  margin-top: 32px;
  cursor: pointer;
}

.cancellationAllowedflexDiv > div > p {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: var(--fontColorNormal3);
  margin-bottom: 12px;
}

.removeIconDiv {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  left: 96%;
  top: -8%;
  background: var(--bgColor1);
  cursor: pointer;
  z-index: 4;
}

.removeIconDivSplGuest {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  left: 553px;
  top: -11px;
  background: var(--bgColor1);
  cursor: pointer;
  z-index: 4;
}

.imgUploadDiv {
  width: 160px;
  height: 160px;
  background: var(--bgColor2);
  border-radius: 4px;
}

.uploadEventImageDiv {
  width: 1204px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  border: 1px dashed #dedfe1;
  border-radius: 4px;
  margin-left: 24px;
  margin-top: 32px;
}

.imgUploadDiv > img {
  width: 100%;
  height: 100%;
}

.imgIconUploadDiv {
  display: flex;
  align-items: center;
  gap: 32px;
}

.browseBtn {
  width: 220px;
  height: 40px;
  border: 1px solid #dedfe1;
  border-radius: 3px;
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: #121212;
  cursor: pointer;
}

.btnCreateEvent {
  margin-bottom: 136px;
  border-top: 1px solid #dedfe1;
  padding-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.createBtn {
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize2);
  line-height: 16px;
  color: #ffffff;
  width: 160.77px;
  height: 40px;
  background: var(--textColorActive);
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.discardBtn {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 16px;
  color: var(--textColorActive);
  width: 160.77px;
  height: 40px;
  background: var(--bgColor1);
  border: 1px solid var(--textColorActive);
  border-radius: 3px;
  cursor: pointer;
}

.chargeNpriceDiv {
  display: flex;
  gap: 25px;
}

.accessibleIntercomDiv {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.priceTimeDiv {
  display: flex;
  /* width: 26rem; */
  margin-top: 12px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dedfe1;
  border-radius: 4px;
}

.inputTextPriceTime {
  border: none;
  width: 11rem;
  height: 40px;
  outline: none;
  padding-left: 24px !important;
}

.inputTextPriceTime1 {
  border: none;
  width: 100%;
  height: 40px;
  outline: none;
  padding-left: 24px !important;
}

.selectBoxDiv {
  width: 100% !important;
  height: 44px !important;
}

.dateRangePicker {
  margin-top: 12px;
  width: 56.3rem !important;
  z-index: 1000 !important;
}

.chargeDiv {
  width: 50%;
}

.timeImg {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 13px;
  top: 10px;
}

.timeImg > img {
  width: 100%;
  height: 100%;
}

.selectDropdown {
  border: 1px solid #d8dae5 !important;
  width: 100%;
  height: 44px;
  padding-left: 10px;
  -webkit-appearance: none !important;
  /* position: relative; */
}

.dropdownDivOuter {
  margin-top: 12px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdownDiv {
  width: 48%;
}

.categoryBtn {
  /* width: 38%; */
  width: 47%;
  height: 38px;
  color: var(--textColorActive);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 4px;
  border: 1px solid #dedfe1;
  background: #fff;
}

/* .customSelect {
        color: #FFFFFF;
        background-color: #333333;
    }  */
.manualMaxMin {
  display: flex;
  justify-content: space-between;
}

.manualMaxMin > div {
  width: 48%;
}

.bookingTypeDiv {
  border: 1px solid var(--borderColor);
  margin-top: 34px;
  margin-left: 24px;
  margin-right: 24px;
  padding-bottom: 32px;
  border-radius: 4px;
}

.rupee {
  position: absolute;
  top: 43px;
  left: 5%;
  font-size: 16px;
}

.rupee1 {
  position: absolute;
  top: 10px;
  left: 5%;
  font-size: 16px;
}

.textAreaCont {
  width: 100% !important;
}

.addionalTextInputArea {
  width: 100% !important;
  min-height: 44px !important;
}

.InctivePreferenceOption {
  border-radius: 8px;
  padding: 4px 12px 4px 12px;
  background-color: #fafafa;
  font-weight: 100;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 3%;
  color: #666666;
  cursor: pointer;
}

.ActivePreferenceOption {
  background-color: #f8ecf3 !important;
  cursor: pointer;
}

.ActivePreferenceOption {
  border-radius: 8px;
  padding: 4px 12px 4px 12px;
}

.TermsConditionsInput {
  width: 100% !important;
  height: 326px !important;
}

.priceCont {
  display: flex;
  gap: 4px;
}

.priceInputPadding {
  padding-left: 12px !important;
}
.textCountDiv {
  text-align: right;
}
.maxCountText {
  /* display: flex; */
  width: 75%;
}
.mainOuterLimitDiv {
  display: flex;
  color: red;
}
.maxLimitCountNumber {
  width: 25%;
  text-align: right;
}

.trainersPresentMainDiv {
  border-radius: 4px;
  border: 1px solid #ecebec;
  margin-left: 24px;
  margin-top: 24px;
  margin-right: 24px;
}

.trainersPresentDiv {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 24px;
  padding-right: 24px;
  padding-right: 24px;
}
.trainersPresentDiv > div {
  display: flex;
  justify-content: space-between;
  gap: 14px;
  border-radius: 4px;
  /* background: #fafafa; */
  padding: 24px;
  margin-top: 24px;
  width: 517px;
}

.trainersPresentBtn {
  background: var(--bgColor2);
  border-radius: 0px 0px 4px 4px;
  padding: 16px 300px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--textColorActive);
  border: none;
  margin-top: 32px;
  cursor: pointer;
}

.dropdownDivProfessional {
  margin-top: 12px !important;
  width: 100% !important;
}

.filterLstMain {
  width: 100%;
  height: 324px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filterbtnDiv {
  display: flex;
  gap: 24px;
  margin-top: 32px;
}
.filterApply {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  height: 32px;
  background: var(--textColorActive);
  border-radius: 4px;
  border: 1px solid var(--textColorActive);
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  cursor: pointer;
  color: #ffffff;
}
.filterReset {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #c5c1c3;
  border-radius: 4px;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  cursor: pointer;
  color: #73696e;
}
.filterMainBtn {
  background-color: #fafafa !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  color: #73696e;
  border: none !important;
  width: 100%;
  height: 45px;
  padding-left: 16px;
  padding-right: 6px;
  border-radius: 4px;
}
.filterDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filterLeftIconDiv {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.filterLeftIconDiv > img {
  width: 100%;
  height: 100%;
}

.filterRightIconDiv {
  width: 32px;
  height: 32px;
}

.profListPaddingSelectAll {
  margin: 0px 0px 10px 0px;
  border-radius: 4px;
  width: 91%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.singleProfSelectAll {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
}

.profListPadding {
  background: #fafafa;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 91%;
}

.singleProf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
}

.filterLst {
  padding: 10px 20px 10px 20px;
  display: flex;
}
.filterLst > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #9d9699;
  margin-top: 16px !important;
  margin-bottom: 8px;
}
.bookingSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  min-width: 475px;
  height: 40px;
  padding: 0px 10px;
}

.bookingSearch > input {
  width: 100%;
  border: none;
  outline: none;
  padding-left: 10px;
}
.searchIcon {
  width: 35px;
  height: 40px;
}

.searchIcon > img {
  width: 100%;
  height: 100%;
}
.profDetails {
  padding: 0px 15px 5px 15px;
}

.profDetailsInnerDiv {
  padding: 0px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
}

.profDetailsInnerDiv > div:first-child {
  color: #666666;
}

.profDetailsInnerDiv > div:last-child {
  color: #121212;
}
.backLessThan {
  width: 64px;
  height: 20px;
}
.horizLine {
  width: 100%;
  border: 1px solid #f2f2f2;
  margin-bottom: 12px;
}

.innerHorizLine {
  width: 100%;
  border: 1px solid #f2f2f2;
  margin-bottom: 10px;
  padding-right: 10px;
}
.scrollDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
