.heading {
  font-weight: var(--fontWeight2);
  font-size: 24px;
  line-height: 28px;
  color: var(--textColorActive1);
  margin-top: 44px;
  text-align: center;
}

.bookingNoMainDiv {
  width: 100%;
  padding: 0px 32px 32px 32px;
}

.customModal {
  max-width: 1289px !important;
  width: 1100px !important;
}

.searchParentDiv {
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 16px;
  margin-bottom: 12px;
  display: flex;
  justify-content: end;
}

.searchDiv {
  width: 85%;
  height: 40px;
  border: 1px solid var(--bgColor2);
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: var(--bgColor2) !important;
  margin-right: 10px;
  flex: 1;
}

.searchDivInput {
  border: none;
  outline: none;
  width: 72vw !important;
  background: var(--bgColor2) !important;
}

.searchIcon {
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin-top: 32px;
}

.paginationValuesDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #4d5259 !important;
}

.paginationValuesDiv > p {
  color: #4d5259 !important;
}

.pagination > div {
  display: flex;
  gap: 44px;
}

.paginationIconDiv {
  display: flex;
  gap: 30px;
  color: #4d5259 !important;
  cursor: pointer;
}
.excelDiv {
  border: 1px solid var(--borderColor);
  border-radius: 5px;
  cursor: pointer;
}
.logoDiv {
  height: 70vh;
}
