.mainDivEventsList {
  /* width: 40%; */
  /* border: 1px solid black; */
}

.topNavEventsList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-right: 10px;
}

/* .topNavEventsList>p { */
/* padding: 4px 8px;
    background: #F4E0EB;
    font-size: var(--fontSize4);
    border-radius: 4px;
    font-weight: var(--fontWeight2);
    font-size: var(--fontSize4);
    line-height: 16px;
    text-transform: uppercase;
    color: #121212; */
/* margin-left: 10px !important; */
/* margin-right: 14px; */
/* } */

.topNavEventsList > h1 {
  font-weight: var(--fontWeight2) !important;
  font-size: var(--fontSize2) !important;
  line-height: 24px !important;
  color: var(--textColor) !important;
}

.middleDivEventsList {
  height: 55vh;
  /* min-height: calc(60vh - 7rem);  */
  overflow: hidden;
  overflow-y: auto;
  /* margin-left: 45px; */
}

.restroCountDiv {
  display: inline-flex;
  padding: 4px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: #591438;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 4px;
  background: #f4e0eb;
}

.monthHeader {
  width: 100%;
  height: 24px;
  background: #fafafa;
  /* border:1px solid black; */
  display: block;
  margin-bottom: 16px;
  padding: auto;
}

.monthHeader > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize3);
  line-height: 18px;
  color: #74676e;
  padding-left: 12px;
  padding-top: 3px;
}
