.mainDivEventsListItems {
  font-style: normal;
  padding: 16px 16px 16px 24px;
  margin-right: 10px;
  margin-bottom: 12px;
  /* height: 164px; */
  border: 1px solid var(--borderColor);
  border-radius: var(--borderRadius);
  cursor: pointer;
}

.titleDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleDiv > p {
  padding: 4px;
  background: var(--bgColor1);
  border-radius: var(--borderRadius);
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize4);
  line-height: 16px;
  letter-spacing: -0.02em;
  color: var(--fontColorActiveTab1);
}

.titleDiv > h1 {
  font-weight: var(--fontWeight2) !important;
  font-size: var(--fontSize1);
  line-height: 24px;
  color: var(--textColor);
}

.ImgEventsListItems > h1 {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize3);
  line-height: 20px;
  color: var(--textColor);
}

/* .amountDateDivEventsListItems {
    display: flex;
    align-items: center;
    justify-content: space-between;
} */

.amountDateDivEventsListItems > h5 {
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--fontColorNormal3) !important;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize5);
  line-height: 16px;
  letter-spacing: 0.1px;
}

.amountDateDivEventsListItems > p {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize3);
  line-height: 24px;
  color: var(--textColor);
}

.amountDateValueDivEventsListItems {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.activetab {
  background: var(--bgColorActiveTab);
  border-radius: var(--borderRadius);
}

.ImgEventsListItems {
  margin-top: 12px;
  display: flex;
  gap: 14px;
  align-items: center;
}

.mainDivEventsListItemsActive {
  font-style: normal;
  padding: 16px 16px 16px 24px;
  margin-right: 10px;
  margin-bottom: 12px;
  /* height: 164px; */
  border: 1px solid var(--bgColorActiveTab);
  border-radius: 4px;
  cursor: pointer;
}

.titleDivActive {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleDivActive > h1 {
  font-weight: var(--fontWeight2) !important;
  font-size: var(--fontSize1);
  line-height: 24px;
  color: var(--fontColorActiveTab1);
}

.titleDivActive > p {
  padding: 4px;
  background: var(--bgColor1);
  border-radius: 4px;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize4);
  line-height: 16px;
  letter-spacing: -0.02em;
  color: var(--fontColorActiveTab1);
}

.statusColorOpen {
  color: #3eb867 !important;
}

.statusColor {
  color: var(--statusClose) !important;
}

.catImg {
  width: 20px;
  height: 20px;
}

.catImg > img {
  width: 100%;
  height: 100%;
}

.timeStatusDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
