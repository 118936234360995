.galleryModal {
  margin: 24px;
}

.galleryMainDiv {
  display: flex;
  flex-wrap: wrap;
  width: 970px;
  gap: 8px;
  height: 400px;
  overflow-y: scroll;
  margin-top: 40px;
}

.closeIcon {
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
}

.eventGalleryNav {
  height: 40px;
  margin-top: 44px;
  display: flex;
  justify-content: space-between;
}

.eventGalleryNav > span {
  display: flex;
  gap: 24px;
}

.eventGalleryNav > h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--textColorActive);
}

.galleryDivOdd {
  width: 300px;
  height: 212px;
  background: #d9d9d9;
  position: relative;
  /* border: 1px solid black; */
}

.galleryDivOdd > img {
  width: 100%;
  height: 100%;
}

.galleryDivEven {
  width: 300px !important;
  height: 212px;
  background: #d9d9d9;
  /* border: 1px solid black; */
  position: relative;
}

.galleryDivEven > img {
  width: 100%;
  height: 100%;
}

.galleryDate {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
  margin-top: 16px;
  margin-bottom: 16px;
}

.uploadBtn {
  height: 40px;
  left: 709px;
  top: 32px;
  background: var(--textColorActive);
  color: #ffffff;
  border-radius: 3px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;

  width: 220px;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
}

.closeBtn {
  height: 40px;
  left: 864px;
  top: 32px;

  background: #ffffff;
  border: 1px solid var(--textColorActive) !important;
  border-radius: 3px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: var(--textColorActive);
  width: 220px;
  padding: 10px 20px;
  margin-right: 17px;
  justify-content: space-between;
  align-items: center;
}

.btnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 20px;
}

.emptyDiv {
  width: 976px;
  height: 428px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px dashed #dedfe1;
  background: #fff;
  margin-top: 40px;
  margin-bottom: 80px;
}

.uploadGalleryImgIcon {
  width: 342.756px;
  height: 271px;
  flex-shrink: 0;
}

.uploadGalleryImgIcon > img {
  /* width: 100%;
    height: 100%; */
}

.galleryP {
  margin-top: 32px;
  margin-bottom: 24px;
}

.galleryP > span {
  color: #74676e;
}

.galleryP {
  color: #121212;
}
