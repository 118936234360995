.mainDivEventsList {
  /* width: 40%; */
  /* border: 1px solid black; */
}

.topNavEventsList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-right: 10px;
}

.topNavEventsList > h1 {
  font-weight: var(--fontWeight2) !important;
  font-size: var(--fontSize2) !important;
  line-height: 24px !important;
  color: var(--textColor) !important;
}

.middleDivEventsList {
  height: 53vh;
  /* min-height: calc(60vh - 7rem);  */
  overflow: hidden;
  overflow-y: auto;
  /* margin-left: 45px; */
}

.topNavEventsList > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  color: #121212;
  background: #f4e0eb;
  border-radius: 4px;
  padding: 4px 8px;
}

.monthHeader {
  width: 100%;
  height: 24px;
  background: #fafafa;
  /* border:1px solid black; */
  display: block;
  margin-bottom: 16px;
  padding: auto;
}

.monthHeader > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize3);
  line-height: 18px;
  color: #74676e;
  padding-left: 12px;
  padding-top: 3px;
}

.searchProfOuterDiv {
  display: flex;
  height: 40px;
  gap: 6px;
  align-items: center;
  background: rgba(250, 250, 250, 1);
  border-radius: 4px;
  margin-bottom: 15px;
}

.searchProfDiv {
  height: 35px;
  width: 100%;
  border: none;
  border-radius: 4px;
  background: rgba(250, 250, 250, 1);
}

.searchProfDiv:focus {
  outline: none;
}

.noDataMainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noDataDiv {
  /* width: 400px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 80px; */
  /* border: 1px solid rgb(232, 229, 229); */
}

.noDataDiv > h2 {
  color: #121212;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
}

.noDataDiv > p {
  color: #74676e;
  font-size: 16px;
  line-height: 28px;
  margin-top: 16px;
}

.noDataBtn {
  width: 160px;
  height: 40px;
  padding: 10px 20px;
  border-radius: 3px;
  border: 1px solid #92215c !important;
  background: #fff;
  color: #92215c !important;
}

.noDataImgDiv {
  width: 225px;
  height: 200px;
}

.noDataImgDiv > img {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #74676e;
}

::-webkit-scrollbar-track {
  background-color: #edebec;
}
