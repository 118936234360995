.viewBookingMainImgDiv {
  display: flex;
  gap: 56px;
  margin-top: 24px;
  border-radius: 12px;
}

.viewBookingMainDiv {
  margin-left: 56px;
  overflow-y: auto;
  height: 65vh;
  padding-bottom: 20px;
}

.categoryTitle {
  color: #121212;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important;
  margin-top: 10px;
}

.navDiv {
  margin-left: 29px;
  margin-top: 12px;
}

.viewBookingImgDiv {
  width: 182px;
  height: 108px;
}

.viewBookingMainImgDiv > div > img {
  width: 100%;
  height: 100%;
  border-radius: 4px !important;
}

.viewBookingMainImgDiv > div > h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #121212;
}

.inputDivMain {
  /* border: 1px solid black; */
  width: 724px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 56px;
  margin-top: 16px;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize3);
  line-height: 20px;
  color: #868686;
}

.inputDiv {
  width: 486px;
  height: 36px;
  background: var(--bgColor2);
  border-radius: 4px;
  padding-left: 24px;
  padding-top: 8px;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize3);
  line-height: 20px;
  color: var(--fontColorNormal2);
}

.btnDiv {
  border-top: 1px solid var(--bgColor2);
  display: flex;
  gap: 24px;
  padding-top: 10px;
  margin-top: 18px;
  margin-bottom: 32px;
  justify-content: center;
}

.cancelBtn {
  width: 200px;
  height: 40px;
  left: calc(50% - 200px / 2 - 288px);
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize2);
  line-height: 16px;
  color: var(--textColorActive);
  background: var(--bgColor1);
  border: 1px solid var(--textColorActive);
  border-radius: 3px;
}

.closeBtn {
  width: 200px;
  height: 40px;
  left: calc(50% - 118px / 2 - 105px);
  padding: 12px 36px;
  background: var(--textColorActive);
  border-radius: 3px;
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize2);
  line-height: 16px;
  color: var(--bgColor1);
}

.open {
  color: #3eb867 !important;
  padding: 8px;
  max-width: 88px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ecfff2;
  border-radius: var(--borderRadius);
  margin-top: 10px;
}

.close {
  color: #ffb752 !important;
  padding: 8px;
  max-width: 93px;
  height: 36px;
  background: #fff4e3;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.cancelled {
  color: #121212 !important;
  padding: 8px;
  margin-top: 10px;
  max-width: 88px;
  height: 36px;
  background: #dbdbdb;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
}
.rejected {
  margin-top: 10px;
  color: #ab0000 !important;
  padding: 8px;
  max-width: 88px;
  height: 36px;
  background: #ffeeee;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fieldsDiv {
  margin-top: 16px;
  color: #868686;
}

.inputTextArea {
  width: 724px;
  height: 44px;
  height: 152px !important;
  margin-top: 12px;
  border-radius: 4px;
  border: 1px solid #dedfe1;
  padding: 10px;
  color: var(--fontColorNormal2);
}
