.tableOuterDiv {
  padding: 0px 20px 20px 25px;
  margin-top: 20px;
  overflow: auto;
  height: 300px;
}

.tableDiv {
  width: 95%;
}

.tabelHead {
  color: rgba(111, 119, 131, 1);
  font-size: 14px;
  font-weight: 600;
}

.userName {
  color: rgba(146, 33, 92, 1);
  font-size: 14px;
  font-weight: 600;
  min-width: 150px;
  max-width: 220px;
  cursor: pointer;
}

.role {
  color: rgba(18, 18, 18, 1);
  font-size: 14px;
  font-weight: 600;
  min-width: 150px;
  max-width: 220px;
}

.access {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  min-width: 120px;
  max-width: 180px;
  border-radius: 50%;
}

.noAccess {
  background: rgba(224, 227, 241, 1);
  min-width: 120px;
  max-width: 180px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 10px 80px 25px 0px;
}

.paginationValuesDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #4d5259 !important;
}

.paginationValuesDiv > p {
  color: #4d5259 !important;
}

.pagination > div {
  display: flex;
  gap: 44px;
}

.paginationIconDiv {
  display: flex;
  gap: 30px;
  color: #4d5259 !important;
  cursor: pointer;
}

.inactiveDiv {
  background: rgba(222, 223, 225, 0.5);
  color: rgba(116, 103, 110, 1);
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 20px;
}
