.mainDivBookEventModal {
  border-radius: 8px;
  width: 760px;
}

.mainDivBookEventModal > div {
  padding-left: 32px;
  padding-right: 32px;
}

.scrollDiv {
  height: 40vh;
  overflow-y: auto;
}

.headingBookEventModal {
  margin-top: 32px;
  color: var(--textColorActive1);
  font-weight: var(--fontWeight2) !important;
  font-size: 24px !important;
  line-height: 28px;
  text-align: center;
  margin-bottom: 16px;
}

.LLabel {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize2) !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2);
  font-weight: var(--fontWeight3);
}

.flexDivBookEventDetail {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.mgbottominputSB {
  margin-bottom: 24px;
  border: 1px solid #ecebec !important;
  border-radius: 4px;
  margin-top: 12px;
}

::placeholder {
  font-style: normal !important;
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize4) !important;
  line-height: 16px !important;
  color: #b7b1b4 !important;
  margin-left: 8px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.LLabelBookSeat {
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize3);
  line-height: 20px;
  color: var(--textColor);
}

.btnDivBookEventDetail {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.bookUnitDiv {
  display: flex;
  align-items: center;
  border: 1px solid var(--bgColor2);
  /* width: 534px; */
  height: 44px;
  background: var(--bgColor2);
  border-radius: 4px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 10px;
  padding-bottom: 24px;
  padding-top: 24px;
}

.bookDiv {
  background: var(--bgColor2);
  padding: 8px 8px;
}

.amountText {
  color: #121212;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.counterEventModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 10px;

  width: 32px;
  height: 32px;
  color: #92215c;
  background: #ffffff;
  border: 0.5px solid #92215c;
  border-radius: 1px;
}

.counterMinusEventModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px;
  gap: 10px;

  width: 24px;
  height: 24px;
  color: #92215c;

  border: 0.5px solid #92215c;
  border-radius: 50%;
}

.selectNoSeats > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: #000000 !important;
}

.memberUnitDiv {
  display: flex;
  align-items: center;
  width: 534px;
  height: 44px;
  background: #fafafa;
  border-radius: 4px;
  margin-bottom: 40px;
  cursor: pointer;
}

.memberUnitSelect {
  width: 274px;
  height: 36px;
  background: #fafafa;
  border-radius: 4px;
}

.memberUnitSelect > p {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #74676e;
  padding: 8px 16px;
}

.memberUnitSelectActive > p {
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize2);
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #92215c;
  padding: 8px 16px;
}

.memberUnitSelectActive {
  width: 274px;
  height: 36px;
  background: #f8ecf3;
  border-radius: 4px;
}

.absoluteDiv {
  position: relative;
}

.nameDiv {
  position: absolute;
  top: 47px;
  right: 13px;
  z-index: 3;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize3);
  line-height: 18px;
  color: var(--fontColorNormal4);
}

.memberError {
  position: absolute;
  top: 80px;
  /* right: 13px; */
  z-index: 3;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize3);
  line-height: 18px;
  color: var(--statusClose);
}

.seatError {
  padding-left: 60px;
  color: var(--statusClose);
}

.bookingP {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: var(--titleColor);
  text-align: center;
  margin-bottom: 15px;
}
.bookingSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-radius: 4px;
  margin-bottom: 20px;
  min-width: 534px;
}

.bookingSearch > input {
  background-color: #fafafa;
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  padding-left: 12px;
}
.searchIcon {
  width: 32px;
  height: 40px;
}

.searchIcon > img {
  width: 100%;
  height: 100%;
}
.menu {
  margin-top: 98px;
  width: 82%;
  background: #ffffff;
  position: absolute;
  top: 60px;
  right: 59px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 1;
}
.menu.active {
  z-index: 1;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}

.menu li:hover {
  background: #f4e0eb;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

.mobileGenderDiv {
  display: flex;
  align-items: center;
  gap: 26px;
  width: 100%;
}

.mobileGenderDiv > div {
  width: 50%;
}
.finalPriceValue {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: end;
}
.divTotalAmount {
  color: #bebebe;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.24px;
  text-decoration: line-through;
}
.divPercentage {
  color: #ab0000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.24px;
}
.eventBookMemberBtn {
  height: 44px;
  color: #92215c;
  padding: 4px 4px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 16px;
  border: 0px solid #663a7e;
  background: #fafafa;
}
.eventBtnMember {
  width: 50%;
  height: 36px;
  font-size: 16px;
  border-radius: 4px;
  background: #f8ecf3;
}
.eventBtnNonMember {
  width: 50%;
  color: #74676e;
  height: 36px;
  letter-spacing: -0.32px;
  background-color: #fafafa;
}
.eventSeatMainDiv {
  padding-left: 16px;
  margin-bottom: 26px;
}
.eventBookIconDiv {
  gap: 8px;
  display: flex;
}
.eventSeatText {
  color: #121212;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.eventBookSeatAmount {
  padding-left: 28px;
  color: #1c0426;
  padding-top: 8px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.eventTextAmountDiv {
  display: flex;
  align-items: center;
  border: 1px solid var(--bgColor2);
  height: 60px;
  background: var(--bgColor2);
  border-radius: 4px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-bottom: 24px;
}
.eventNumLeft {
  color: #92215c;
  font-family: Poppins;
  padding: 0px 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.eventNumRight {
  color: #121212;
  font-family: Poppins;
  padding: 0px 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.eventTextLeft {
  color: #868686;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.eventBookSlash {
  color: #000;
  padding-left: 4px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.eventTableSeatText {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 16px;
}

.leftWaitingTotal {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #868686;
  text-align: right;
}

.colorRed {
  color: #ab0000;
}
.colorBlack {
  color: #000;
}
.colorPurple {
  color: #663879;
}

/* new implementation */
.priceTag {
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.eventsVaryingColorDiv {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
}

.strikedPriceValueDiv {
  text-decoration: line-through 1px #868686;
  position: relative;
  white-space: nowrap;
  font-weight: 4000;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #bebebe;
}
