.timerDiv {
    width: 100%;
    height: 40px;
    border: 1px solid #DEDFE1;
    outline: none !important;
    border-radius: 4px;
    padding-left: 10px;
}

.isBorder {
    border: none !important;
}

.timerOption:hover {
    background-color: #F8ECF3;
}

.timerOption {
    padding: 5px 15px;
}

.timeImg {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 13px;
    top: 10px;
}

.timeImg>img {
    width: 100%;
    height: 100%;
}