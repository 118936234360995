.mainDivFnBDetailsCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.searchMenuListDiv {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.searchBarMainDiv {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 40px;
}
.searchBarMainDivPadding {
  flex: 1;
  height: 40px;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid var(--borderColor3);
  padding: 10px 16px;
}

.searchIconDiv {
  display: flex;
  width: 48px;
  align-items: flex-start;
  gap: 10px;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
}
.searchIconDiv > img {
  height: 100%;
  width: 100%;
}
.searchBarMainDivPadding > input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}

.subMenuList {
  display: flex;
  height: 45px;
  /* padding-right: 24px; */
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  width: 100%;
  max-width: 600px;
  overflow-x: scroll;
}

.menuListDivPadding {
  display: flex;
  height: 40px;
  padding: 4px 12px;
  align-items: center;
  gap: 8px;
  /* width: 100%; */
  border-radius: 8px;
  background: var(--bgColor2);
  backdrop-filter: blur(14px);
}
.categoryNameDiv {
  white-space: nowrap !important;
}
.categoryDivMain {
  width: 100%;
}
.categoryCardContent {
  font-size: var(--fontSize2);
  line-height: var(--fontSize2);
  letter-spacing: 0.03em;
  flex: none;
  order: 0;
  flex-grow: 0;
  white-space: pre;
  cursor: pointer;
}

.activeCat {
  background: var(--bgcolor);
  color: var(--fontColorActiveTab2);
  font-size: var(--fontSize4);
  font-weight: var(--fontWeight1);
  letter-spacing: 0.36px;
}
.categoryCard {
  padding: 4px 12px;
  height: 40px;
  /* background: var(--GrayLighterColor); */
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(14px);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.menuItemListDiv {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
  overflow-y: auto;
  height: 30vh;
}
@media screen and (max-width: 1040px) {
  .generalInfoDivEventsDetailsCard {
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    margin-left: 10px;
  }
}

/* -----------------Hiding Scrollbar---------- */
.subMenuList::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.subMenuList {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* -----------------Hiding Scrollbar---------- */
