.title {
  color: var(--textColorActive1) !important;
  font-size: var(--fontSize1);
  font-style: normal;
  font-weight: var(--fontWeight1);
  line-height: 24px;
  text-align: center;
  padding-left: 100px;
  padding-right: 132px;
}

.text {
  color: #73696e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin: 12px 0px;
  padding-left: 100px;
  padding-right: 132px;
}

.mainDiv {
  width: 650px;
  padding-left: 32px;
  padding-top: 32px;
  /* padding-bottom: 32px; */
}

.labelFont {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.fieldsDiv {
  margin-top: 20px;
}

.star {
  color: var(--statusClose);
  margin-left: 4px;
}

.inputTextArea {
  width: 100% !important;
  height: 152px !important;
  margin-top: 12px;
  border-radius: 4px;
  border: 1px solid #dedfe1;
}

.suspendDiv {
  display: flex;
  border-radius: 4px;
  border: 1px solid #dedfe1;
  background: #fff;
  justify-content: space-between;
  padding: 16px;
  margin-top: 28px;
}

.suspendTitle {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.disclaimer {
  color: #73696e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 8px;
  max-width: 472px;
}

.btnDiv {
  display: flex;
  justify-content: center;
  padding-top: 16px;
  gap: 24px;
  padding-bottom: 32px;
  margin-top: 48px;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  left: 0;
}
.UnavailableDateDiv {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.mainStartDivDate {
  width: 100%;
}
.mainEndDivDate {
  width: 100%;
}
.dateText {
  margin-bottom: 8px;
}
.unavailableTextDiv {
  padding-bottom: 86px;
  padding-right: 32px;
  position: relative;
  overflow: scroll;
  height: 75vh;
}
