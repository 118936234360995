.viewBookingMainImgDiv {
  display: flex;
  gap: 56px;
  border-radius: 12px;
  padding-bottom: 10px;
  margin-top: 20px;
}

.viewBookingMainDiv {
  margin-left: 56px;
  overflow-y: auto;
  height: 65vh;
  padding-bottom: 30px;
}

.greyBox {
  background: var(--bgColor2) !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  margin-top: 10px;
  padding: 6px 16px;
}

.specialRequestBox {
  background: var(--bgColor2) !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  margin-top: 20px;
  padding: 6px 16px;
}

.navDiv {
  margin-left: 29px;
  margin-top: 12px;
}

.viewBookingImgDiv {
  width: 182px;
  height: 108px;
}

.viewBookingMainImgDiv > div > img {
  width: 100%;
  height: 100%;
  border-radius: 4px !important;
}

.viewBookingMainImgDiv > div > h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #121212;
}

.inputDivMain {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 56px;
  font-weight: var(--fontWeight3);
  font-size: 14px;
  line-height: 20px;
  color: #868686;
}

.inputDiv {
  height: 36px;
  border-radius: 4px;
  font-weight: var(--fontWeight2);
  font-size: 14px;
  line-height: 20px;
  color: var(--fontColorNormal2);
  display: flex;
  align-items: center;
}

.btnDiv {
  border-top: 1px solid var(--bgColor2);
  display: flex;
  gap: 24px;
  margin-bottom: 25px;
  justify-content: center;
}

.cancelBtn {
  width: 200px;
  height: 40px;
  left: calc(50% - 200px / 2 - 288px);
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize2);
  line-height: 16px;
  color: var(--textColorActive);
  background: var(--bgColor1);
  border: 1px solid var(--textColorActive);
  border-radius: 3px;
}

.closeBtn {
  width: 200px;
  height: 40px;
  left: calc(50% - 118px / 2 - 105px);
  padding: 12px 36px;
  background: var(--textColorActive);
  border-radius: 3px;
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize2);
  line-height: 16px;
  color: var(--bgColor1);
}

.ordered {
  color: #690a3c !important;
  padding: 8px;
  max-width: 88px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8ecf3;
  border-radius: var(--borderRadius);
}

.cancelled {
  color: #121212 !important;
  padding: 8px;
  max-width: 100px;
  height: 36px;
  background: #dbdbdb;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.completed .delivered {
  color: #4e67b5 !important;
  padding: 8px;
  max-width: 95px;
  height: 36px;
  background: #eef2fd;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.inProgress {
  color: #ffb752 !important;
  padding: 8px;
  max-width: 88px;
  height: 36px;
  background: #fff4e3;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.noShow {
  color: #ab0000 !important;
  padding: 8px;
  max-width: 88px;
  height: 36px;
  background: #ffeeee;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.inTransit,
.upcoming {
  color: #3eb867 !important;
  padding: 8px;
  max-width: 100px;
  height: 36px;
  background: #ecfff2;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fieldsDiv {
  color: #868686;
}

.mobileGenderDiv {
  display: flex;
  align-items: center;
  gap: 26px;
  width: 90%;
  padding: 10px 0px;
}

.mobileGenderDiv > div {
  width: 50%;
}
.LLabel {
  font-weight: var(--fontWeight3) !important;
  font-size: 14px;
  line-height: 20px !important;
  color: var(--fontColorNormal2);
  font-weight: var(--fontWeight3);
}
.radioSelector {
  height: 52px;
  border-radius: 2px;
  filter: drop-shadow(0px 2px 8px rgba(18, 18, 18, 0.12));
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 15px;
  gap: 30px;
}

.radioSelectorDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  color: #868686;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  cursor: pointer;
}

.isRadioActive {
  color: #92215c;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
}

.labelFont {
  color: #121212;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.star {
  color: var(--statusClose);
}

.inputTextArea {
  width: 500px !important;
  height: 80px !important;
  margin-top: 12px;
  border-radius: 4px;
  border: 1px solid #dedfe1;
  font-size: 14px;
}
