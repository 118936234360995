.creatEventMainDiv {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* align-items: center; */
  margin-left: 24px;
  padding-left: 24px;
  padding-right: 80px;
  /* max-width: 121.2rem !important; */
}

.creatEventMainDiv::-webkit-scrollbar {
  display: none;
}

.mainDiv {
  /* width: 1252px; */
  /* height: 740px; */

  padding-bottom: 40px;
  background: #ffffff;
  border: 1px solid #ecebec;
  border-radius: 4px;
}

.titleDiv {
  background: #f8ecf3;
  border-radius: 4px 4px 0px 0px;
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 4px;
}

.brMargin {
  margin-top: 12px;
}

.titleDiv > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize2) !important;
  line-height: 20px;
  color: var(--textColorActive1) !important;
}

.labelFont {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize2) !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2) !important;
}

.timePickerMainDiv {
  display: flex;
  gap: 48px;
}

.star {
  color: var(--statusClose);
}

.displayFlexDiv {
  display: flex;
  gap: 56px;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
}

.inputText {
  margin-top: 12px;
  width: 56.2rem !important;
  height: 44px !important;
}

.inputTextArea {
  width: 56.2rem !important;
  height: 152px !important;
  margin-top: 12px;
}

.radioLabel {
  font-style: normal;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: #666666;
}

.radioDiv {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
  /* border: 1px solid #c5c0c7; */
  /* padding: 8px 12px; */
  /* border-radius: 4px; */
}

.radioDivActive {
  display: flex;
  align-items: center;
  gap: 12px;
  /* border: 1px solid var(--fontColorActiveTab1); */
  /* padding: 8px 12px; */
  height: 44px;
  /* border-radius: 4px; */
  color: var(--fontColorActiveTab1);
  /* background-color: var(--bgColorActiveTab); */
}

.radioDivActive > label {
  color: var(--fontColorActiveTab1);
}

.radioMainDiv {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
  width: 562px;
  border: 1px solid beige;
}

.ImgUploadGuestDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  margin-top: 12px;
  position: relative;
}

.guestAdd {
  position: absolute;
  left: 470px;
  top: 12px;
  cursor: pointer;
}

.cancellationAllowedDiv {
  border: 1px solid #ecebec;
  border-radius: 4px;
  margin-left: 24px;
  margin-right: 24px;
  width: 1204px;
  margin-top: 16px;
}

.cancellationAllowedflexDiv {
  display: flex;
  gap: 80px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
  /* border: 1px solid #ECEBEC; */
  position: relative;
}

.cancellationAllowedBtn {
  background: #fafafa;
  border-radius: 0px 0px 4px 4px;
  padding: 16px 557px;
  width: 100%;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: var(--textColorActive);
  border: none;
  margin-top: 32px;
  cursor: pointer;
}

.cancellationAllowedflexDiv > div > p {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: #9d9699;
  margin-bottom: 12px;
}

.removeIconDiv {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  left: 1167px;
  top: 0px;
  background: #ffffff;
  cursor: pointer;
  z-index: 4;
}

.removeIconDivSplGuest {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  left: 553px;
  top: -11px;
  background: #ffffff;
  cursor: pointer;
  z-index: 4;
}

.imgUploadDiv {
  width: 160px;
  height: 160px;
  background: var(--bgColor2);
  border-radius: 4px;
}

.uploadEventImageDiv {
  width: 1204px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  border: 1px dashed #dedfe1;
  border-radius: 4px;
  margin-left: 24px;
  margin-top: 32px;
}

.imgUploadDiv > img {
  width: 100%;
  height: 100%;
}

.imgIconUploadDiv {
  display: flex;
  align-items: center;
  gap: 32px;
}

.browseBtn {
  width: 220px;
  height: 40px;
  border: 1px solid #dedfe1;
  border-radius: 3px;
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: #121212;
  cursor: pointer;
}

.mainDiv {
  /* width: 1252px !important; */
  /* height: 740px; */
  padding-bottom: 40px;
  background: var(--bgColor1);
  border: 1px solid var(--borderColor);
  border-radius: 4px;
}

.titleDiv {
  background: var(--bgcolor);
  border-radius: 4px 4px 0px 0px;
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 4px;
}

.brMargin {
  margin-top: 12px;
}

.titleDiv > p {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 20px;
  color: var(--textColorActive1) !important;
}

.labelFont {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2) !important;
}

.timePickerMainDiv {
  display: flex;
  gap: 48px;
}

.star {
  color: var(--statusClose);
}

.displayFlexDiv {
  display: flex;
  gap: 56px;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
}

.inputText {
  margin-top: 12px;
  width: 56.2rem !important;
  height: 44px !important;
}

.inputTextArea {
  width: 56.2rem !important;
  height: 152px !important;
  margin-top: 12px;
}

.radioLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #666666;
}

.radioDiv {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
  /* border: 1px solid #c5c0c7; */
  /* padding: 8px 12px; */
  /* border-radius: 4px; */
}

.radioDivActive {
  display: flex;
  align-items: center;
  gap: 12px;
  /* border: 1px solid var(--fontColorActiveTab1); */
  /* padding: 8px 12px; */
  height: 44px;
  /* border-radius: 4px; */
  color: var(--fontColorActiveTab1);
  /* background-color: var(--bgColorActiveTab); */
}

.radioDivActive > label {
  color: var(--fontColorActiveTab1);
}

.radioMainDiv {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
  width: 562px;
}

.ImgUploadGuestDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  margin-top: 12px;
  position: relative;
}

.guestAdd {
  /* position: absolute;
    left: 470px;
    top: 12px;  */
  cursor: pointer;
}

.guestImg {
  /* position: absolute;
    right : 69px;
    top: 0 px;  */
  cursor: pointer;
}

.cancellationAllowedDiv {
  border: 1px solid var(--borderColor);
  border-radius: 4px;
  margin-left: 24px;
  margin-right: 24px;
  width: 1204px;
  margin-top: 16px;
}

.cancellationAllowedflexDiv {
  display: flex;
  gap: 80px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
  /* border: 1px solid #ECEBEC; */
  position: relative;
}

.cancellationAllowedBtn {
  background: var(--bgColor2);
  border-radius: 0px 0px 4px 4px;
  padding: 16px 557px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--textColorActive);
  border: none;
  margin-top: 32px;
  cursor: pointer;
}

.cancellationAllowedflexDiv > div > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--fontColorNormal3);
  margin-bottom: 12px;
}

.removeIconDiv {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  left: 1167px;
  top: 0px;
  background: var(--bgColor1);
  cursor: pointer;
  z-index: 4;
}

.removeIconDivSplGuest {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  left: 527px;
  background: var(--bgColor1);
  cursor: pointer;
  z-index: 4;
}

.imgUploadDiv {
  width: 160px;
  height: 160px;
  background: var(--bgColor2);
  border-radius: 4px;
}

.uploadEventImageDiv {
  width: 1204px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  border: 1px dashed #dedfe1;
  border-radius: 4px;
  margin-left: 24px;
  margin-top: 32px;
}

.imgUploadDiv > img {
  width: 100%;
  height: 100%;
}

.imgIconUploadDiv {
  display: flex;
  align-items: center;
  gap: 32px;
}

.browseBtn {
  width: 220px;
  height: 40px;
  border: 1px solid #dedfe1;
  border-radius: 3px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #121212;
  cursor: pointer;
}

.btnCreateEvent {
  margin-bottom: 136px;
  border-top: 1px solid #dedfe1;
  padding-top: 10px;
  /* width: 1252px; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.createBtn {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  width: 160.77px;
  height: 40px;
  background: var(--textColorActive);
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.discardBtn {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: var(--textColorActive);
  width: 160.77px;
  height: 40px;
  background: var(--bgColor1);
  border: 1px solid var(--textColorActive);
  border-radius: 3px;
  cursor: pointer;
}

.dateRangePicker {
  margin-top: 12px !important;
  width: 56.3rem !important;
  z-index: 1000 !important;
}

.chargeNpriceDiv {
  display: flex;
  gap: 25px;
  align-items: center;
}

.specialGuestLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnCreateEvent {
  margin-bottom: 136px;
  border-top: 1px solid #dedfe1;
  padding-top: 10px;
  /* width: 1252px; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.createBtn {
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize2);
  line-height: 16px;
  color: #ffffff;
  width: 160.77px;
  height: 40px;
  background: var(--textColorActive);
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.discardBtn {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 16px;
  color: var(--textColorActive);
  width: 160.77px;
  height: 40px;
  background: #ffffff;
  border: 1px solid var(--textColorActive);
  border-radius: 3px;
  cursor: pointer;
}

.rupee {
  position: absolute;
  top: 40px;
  left: 236px;
  font-size: 20px;
}
