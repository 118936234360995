.title {
    color: var(--anacity-new-red-r-500, #591438);
    font-size: var(--fontSize1);
    font-style: normal;
    font-weight: var(--fontWeight1);
    line-height: 24px;
    text-align: center;
    padding-bottom: 40px;
    margin-top: 32px;
}

.categoryMainDiv {
    padding-left: 60px;
    padding-right: 60px;
    overflow-y: auto;
    height: 50vh;
}

.categoryDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    background: var(--bgColor2);
    min-width: 414px;
    height: 52px;
    margin-bottom: 8px;
    padding: 16px;
}

.categoryDiv>p {
    color: var(--fontColorNormal2);
    font-size: var(--fontSize3);
    font-weight: var(--fontWeight3);
    line-height: 20px;
}

.btnDiv {
    display: flex;
    justify-content: center;
    margin-top: 68px;
    margin-bottom: 32px;
    gap: 24px;
}

.editBtn {
    color: var(--textColorActive) !important;
    font-size: var(--fontSize3) !important;
    font-weight: var(--fontWeight1) !important;
    line-height: 20px;
    cursor: pointer !important;
}