.timePickerDiv {
  width: 100%;
  height: 44px !important;
  /* border: 1px solid black;  */
}
.timePickerDivDisabled {
  width: 100%;
  height: 44px !important;
  background: #fafafa;
}
