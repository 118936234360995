.dataContainer {
  background-color: #fafafa;
}
.tittle {
  padding: 12px 0px;
  gap: 76px;
  border-bottom: 1px;
  border-bottom: 1px solid var(--borderColor);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: var(--textColor);
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 4;
}
.requestStatusDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--bgcolor);
  width: 100%;
  height: 64px;
  padding: 12px 32px 12px 32px;
  gap: 10px;
  border-radius: 4px;
}
.headingDiv {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
}
.detailsDiv {
  background: var(--bgColor2);
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.title2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.dataKeyValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dataValue {
  color: var(--textColor);
  font-weight: 500;
}
.queryDiv {
  background-color: white;
  padding: 16px;
}
.queryBox {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.memberDataDiv {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.paddingBottom {
  padding: 12px;
}
.selctBoxDiv {
  display: flex;
  align-items: center;
  min-width: 115px;
  border-radius: 8px;
  background: #fff;
}
