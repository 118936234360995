.mainDivBookEventModal {
  border-radius: 8px;
}

.mainDivBookEventModal > div {
  padding-left: 50px;
  padding-right: 50px;
}

.scrollDiv {
  height: 55vh;
  overflow-y: auto;
  margin-bottom: 10px;
}

.headingBookEventModal {
  margin-top: 32px;
  color: var(--textColorActive1);
  font-weight: var(--fontWeight2) !important;
  font-size: 24px !important;
  line-height: 28px;
  text-align: center;
  margin-bottom: 16px;
}

.mgbottominputSB {
  margin-bottom: 24px;
  border: 1px solid #ecebec !important;
  border-radius: 4px;
  margin-top: 12px;
}

.LLabel {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize2) !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2);
  font-weight: var(--fontWeight3);
}

.dropdownDivOuter {
  margin-top: 12px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdownDiv {
  width: 100%;
}

.btnDivBookEventDetail {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 32px;
  margin-bottom: 32px;
  /* border: 2px solid green; */
}

/* Upload Image Form CSS */

.browseBtn {
  width: 120px;
  height: 40px;
  border: 1px solid #dedfe1;
  border-radius: 3px;
  font-weight: var(--fontWeight1);
  background-color: white;
  font-size: var(--fontSize2);
  line-height: 20px;
  color: var(--fontColorNormal2);
  cursor: pointer;
}

.uploadImgDiv {
  padding: 40px 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  border: 1px dashed #dedfe1;
  border-radius: 10px;
}

.imgIconDiv {
  width: 160px;
  height: 160px;
  position: relative;
}

.imgIconDiv > img {
  width: 100%;
  height: 100%;
}

.previewDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.closeIcon {
  position: absolute;
  right: -5px;
  top: -7px;
  cursor: pointer;
}

.imgSizeText {
  color: #868686;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.error {
  padding-left: 23px;
  color: red;
}

.coverDiv {
  position: absolute;
  display: flex;
  bottom: 9px;
  background: #efefef;
  border-radius: 8px;
  height: 24px;
  width: 152px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  left: 3px;
}

.star {
  color: var(--statusClose);
}
