.title {
  color: var(--anacity-new-red-r-500, #591438);
  font-size: var(--fontSize1);
  font-style: normal;
  font-weight: var(--fontWeight1);
  line-height: 24px;
  text-align: center;
  padding-bottom: 40px;
  margin-top: 32px;
}

.mainDiv {
  padding-left: 60px;
  padding-right: 60px;
}

.labelFont {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize2) !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2) !important;
}

.star {
  color: var(--statusClose);
}

.inputText {
  margin-top: 12px !important;
  width: 100% !important;
  height: 44px !important;
}

.btnDiv {
  display: flex;
  justify-content: center;
  margin-top: 68px;
  margin-bottom: 32px;
  gap: 24px;
}

.btnDivService {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 32px;
  gap: 24px;
}

.InputDiv1 {
  margin-bottom: 40px;
  margin-top: 24px;
}

.labelFont {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2) !important;
  line-height: 20px;
  color: var(--fontColorNormal2) !important;
  margin-bottom: 12px;
}

.ImgUploadMainDiv {
  width: 534px;
  border: 3px dashed var(--borderColor3);
  border-radius: 4px;
  min-height: 160px;
  cursor: pointer;
  margin-top: 12px;
}

.ImageDiv {
  display: flex;
  align-items: center;
  gap: 32px;
}

.imgUploadDiv1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 38px;
}

.uploadImgDiv {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 32px;
    margin-left: 24px;
    margin-right: 24px; */
  /* border: 1px dashed #DEDFE1; */
  /* background-color: grey; */
  padding: 24px;
}

.imgIconDiv {
  width: 80px;
  height: 80px;
  position: relative;
}

.imgIconDiv > img {
  width: 100%;
  height: 100%;
}

.previewDiv {
  display: flex;
  align-items: center;
  gap: 10px;
}

.closeIcon {
  position: absolute;
  right: -5px;
  top: -7px;
  cursor: pointer;
}

.browseBtn {
  width: 100px;
  height: 32px;
  border: 1px solid #dedfe1;
  border-radius: 3px;
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize4);
  line-height: 20px;
  color: #121212;
  cursor: pointer;
}

.error {
  padding-left: 23px;
  color: red;
  position: absolute;
  bottom: -15px;
  left: -2px;
}

.suspendDiv {
  display: flex;
  /* align-items: center; */
  border-radius: 4px;
  border: 1px solid #dedfe1;
  background: #fff;
  justify-content: space-between;
  padding: 16px;
  margin-top: 32px;
}

.suspendTitle {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
