.title {
  color: var(--textColorActive1) !important;
  font-size: var(--fontSize1);
  font-style: normal;
  font-weight: var(--fontWeight1);
  line-height: 24px;
  text-align: center;
  padding-left: 164px;
  padding-right: 164px;
}

.text {
  color: #73696e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-top: 12px;
}

.mainDiv {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.labelFont {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.fieldsDiv {
  margin-top: 40px;
}

.star {
  color: var(--statusClose);
}

.inputTextArea {
  width: 100% !important;
  height: 152px !important;
  margin-top: 12px;
  border-radius: 4px;
  border: 1px solid #dedfe1;
}

.suspendDiv {
  display: flex;
  /* align-items: center; */
  border-radius: 4px;
  border: 1px solid #dedfe1;
  background: #fff;
  justify-content: space-between;
  padding: 16px;
  margin-top: 32px;
}

.suspendTitle {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.disclaimer {
  color: #73696e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 8px;
}

.btnDiv {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 55px;
}
