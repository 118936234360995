.selectedMainDiv {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid rgba(236, 235, 237, 1);
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  min-width: 162px;
  max-width: 190px;
}

.topDiv {
  display: flex;
  gap: 10px;
}

.leftDiv {
  /* height: 40px; */
  /* width: 40px; */
}

.rightDiv {
}

.bottomDiv {
  display: flex;
  justify-content: center;
}
