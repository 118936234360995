.mainDiv {
  width: 100%;
}

.mainMidDiv {
  display: flex;
}

.divList {
  width: 42.8rem;
  border: 1px solid var(--borderColor);
  border-radius: 4px;
  padding: 12px;
  margin-left: 24px;
}

.noDataMainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noDataDiv {
  /* width: 400px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 80px; */
  /* border: 1px solid rgb(232, 229, 229); */
}

.noDataDiv > h2 {
  color: #121212;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
}

.noDataDiv > p {
  color: #74676e;
  font-size: 16px;
  line-height: 28px;
  margin-top: 16px;
}

.noDataBtn {
  width: 160px;
  height: 40px;
  padding: 10px 20px;
  border-radius: 3px;
  border: 1px solid #92215c !important;
  background: #fff;
  color: #92215c !important;
}

.noDataImgDiv {
  width: 225px;
  height: 200px;
}

.noDataImgDiv > img {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #74676e;
}

::-webkit-scrollbar-track {
  background-color: #edebec;
}

.noDataMainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noDataDiv {
  /* width: 400px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 80px; */
  /* border: 1px solid rgb(232, 229, 229); */
}

.noDataDiv > h2 {
  color: #121212;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
}

.noDataDiv > p {
  color: #74676e;
  font-size: 16px;
  line-height: 28px;
  margin-top: 16px;
}

.noDataBtn {
  width: 160px;
  height: 40px;
  padding: 10px 20px;
  border-radius: 3px;
  border: 1px solid #92215c !important;
  background: #fff;
  color: #92215c !important;
}

.noDataImgDiv {
  width: 225px;
  height: 200px;
}

.noDataImgDiv > img {
  width: 100%;
  height: 100%;
}
