.formDiv {
  padding-left: 60px;
  padding-right: 60px;
  height: 67vh;
  overflow-y: auto;
}

.InputDiv {
  margin-bottom: 24px;
}

.InputDiv1 {
  margin-bottom: 40px;
}

.labelFont {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2) !important;
  line-height: 20px;
  color: var(--fontColorNormal2) !important;
  margin-bottom: 12px;
}

.inputField {
  margin-top: 12px;
}

.popularEventTitle {
  font-weight: var(--fontWeight2);
  font-size: 24px;
  line-height: 28px;
  color: var(--textColorActive1) !important;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 40px;
}

.popularEventp {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: var(--fontColorNormal3) !important;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 40px;
}

.ImgUploadMainDiv {
  width: 534px;
  border: 3px dashed var(--borderColor3);
  border-radius: 4px;
  min-height: 160px;
  cursor: pointer;
  margin-top: 12px;
}

.ImageDiv {
  display: flex;
  align-items: center;
  gap: 32px;
}

.imgUploadDiv1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 38px;
}

.btnDivGenerateOffer {
  display: flex;
  gap: 20px;
  text-align: center;
  justify-content: center;
  margin-bottom: 32px;
  padding-top: 20px;
}

.imgUploadDiv1 > p {
  font-style: normal;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 20px;
  text-align: center;
  color: var(--fontColorNormal2);
}

.mainDiv {
  width: 1252px;
  padding-bottom: 40px;
  background: #ffffff;
  border: 1px solid #ecebec;
  border-radius: 4px;
}

.titleDiv {
  background: #f8ecf3;
  border-radius: 4px 4px 0px 0px;
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 4px;
}

.browseBtn {
  width: 100px;
  height: 32px;
  border: 1px solid #dedfe1;
  border-radius: 3px;
  font-weight: var(--fontWeight1);
  font-size: var(--fontSize4);
  line-height: 20px;
  color: #121212;
  cursor: pointer;
}

.uploadImgDiv {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 32px;
  margin-left: 24px;
  margin-right: 24px; */
  /* border: 1px dashed #DEDFE1; */
  /* background-color: grey; */
  padding: 24px;
}

.imgIconDiv {
  width: 80px;
  height: 80px;
  position: relative;
}

.imgIconDiv > img {
  width: 100%;
  height: 100%;
}

.previewDiv {
  display: flex;
  align-items: center;
  gap: 10px;
}

.closeIcon {
  position: absolute;
  right: -5px;
  top: -7px;
  cursor: pointer;
}

/* .labelFont {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize2) !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2) !important;
} */
.radioMainDiv {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 10px;
  width: 100% !important;
}

.radioDiv {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
}

.radioDivActive {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
  color: var(--fontColorActiveTab1);
}

.radioLabel {
  font-style: normal;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: #666666;
}

.timerInput {
  margin-top: 14px;
  /* width: 254px; */
  margin-bottom: 24px;
}

/* ------------ */
.titleFacChargeDiv {
  border-radius: 4px;
  background: var(--bgcolor);
  box-shadow: 0px 0px 4px 0px rgba(18, 18, 18, 0.16);
  padding: 24px 16px;
  margin-bottom: 32px;
}

.titleFacChargeDiv > h3 {
  color: #121212;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
}

.facCharge {
  color: #767c82;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.facChargeValue {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.star {
  color: var(--statusClose);
}

.error {
  padding-left: 23px;
  color: red;
  position: absolute;
  bottom: -15px;
  left: -2px;
}
/* venue-------------- */
.titleChargeDiv {
  border-radius: 4px;
  /* box-shadow: 0px 0px 4px 0px rgba(18, 18, 18, 0.16); */
  padding: 24px 16px;
  margin-bottom: 32px;
  border: 1px solid var(--borderColor3);
}
.titleChargeDiv > span {
  display: flex;
  justify-content: space-between;
  padding-right: 100px;
}
.titleChargeDiv > h3 {
  color: #121212;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
}
