.BHEventtableHead {
  height: 52px;
  border-radius: 4px !important;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.12);
  padding: 16px;
  border: 1px solid black;
  top: 0;
  position: sticky;
}

.BHEventtableHead > th {
  color: #767c82;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 20px;
  /* min-width: 140px; */
  border: none !important;
  text-align: start;
  padding-left: 16px;
}

.BHEventtableData {
  height: 52px !important;
  flex-shrink: 0;
  border-radius: 4px !important;
  background: #fafafa !important;
  border: 1px solid black;
  margin-top: 10px !important;
}

.BHEventtableData > td {
  color: #121212;
  font-size: 14px;
  font-family: Poppins;
  line-height: 20px;
  text-align: start;
  border: none !important;
  padding-left: 16px;
  min-width: 260px;
}

.open {
  color: #3eb867 !important;
  padding: 8px;
  max-width: 80px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ecfff2;
  border-radius: var(--borderRadius);
}

.cancelled {
  color: #121212 !important;
  padding: 8px;
  max-width: 95px;
  height: 36px;
  background: #dbdbdb;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.completed {
  color: #4e67b5 !important;
  padding: 8px;
  max-width: 95px;
  height: 36px;
  background: #eef2fd;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.view {
  cursor: pointer;
  color: #ac1063 !important;
}

.actionBtnDiv {
  display: flex;
  gap: 20px;
  align-items: center;
}

.actionDiv {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.actionDiv > img {
  width: 100%;
  height: 100%;
}

.noDataMainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noDataDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.noDataDiv > span {
  width: 170px;
  height: 170px;
}

.noDataDiv > span > img {
  width: 100%;
  height: 100%;
}

.noDataDiv > h2 {
  color: #121212;
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  padding-left: 28px;
}

.noDataDiv > p {
  color: #74676e;
  font-size: 24px;
  line-height: 28px;
  margin-top: 16px;
}

.close {
  color: #ffb752 !important;
  padding: 8px;
  max-width: 136px;
  height: 36px;
  background: #fff4e3;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.rejected {
  color: #ab0000 !important;
  padding: 8px;
  max-width: 88px;
  height: 36px;
  background: #ffeeee;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  align-items: center;
}

.id {
  color: var(--textColorActive) !important;
  cursor: pointer;
}
