.calenderDiv {
  padding: 12px;

  margin-left: 24px;
  margin-right: 80px;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 1px solid #ecebec;
  border-radius: 4px;
}

.calenderOuterDiv {
  width: 100%;
}

.calenderMainDiv {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* grid-template-areas: "a b c d e";  */
  /* flex-wrap: wrap;
    justify-content: center; */
  /* width: 500px; */
  /* margin: auto;  */
}

.calenderMainDiv > div {
  /* width: 244px; */
  height: 232px;
  border: 1px solid #dedfe1;
}

.navCalender > h1 {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize2);
  line-height: 24px;
  color: #121212;
}

.navCalender {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.calenderDivScroll {
  height: 60vh;
  /* border: 1px solid red; */
  overflow-y: auto;
}

.eventDisplay {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.dayDiv {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize1);
  line-height: 24px;
  color: #74676e;
  display: flex;
  margin-top: 12px;
  align-items: center;
  justify-items: flex-start;
  margin-left: 12px;
  text-transform: uppercase;
}

.eventDisplay > span {
  width: 21px;
  height: 20px;
  background: #ffffff;
  border-radius: 4px;
  padding: 4px;
  margin-right: 12px;
  margin-left: 12px;
}

.eventDisplay > span > p {
  font-weight: var(--fontWeight1);
  font-size: 8px;
  line-height: 12px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #690a3c;
}

/* .eventDisplay>p {

    font-weight: var(--fontWeight2);
    font-size: var(--fontSize4);
    line-height: 20px;
    color: #121212;
} */
.eventDisplay > ul > li {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 20px;
  color: #121212;
}

.eventName {
  color: #121212 !important;
  text-align: start !important;
}

.eventDateDiv {
  background-color: #e0f2f4;
  cursor: pointer;
}

.dropDownDiv {
  display: flex;
  align-items: center;
  margin-right: 24px;
  gap: 24px;
}

.eventCalenderLogoDiv {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.eventCalenderLogoDiv > img {
  width: 100%;
  height: 100%;
}

.viewAllDiv {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  text-align: right;
  color: var(--textColorActive);
  margin-right: 11px;
  margin-top: 24px;
}

ul {
  margin-left: 36px;
}
