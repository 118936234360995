.topNavMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
  /* gap: 76px; */
  height: 64px;
  /* left: 120px; */
  top: 80px;

  /* anacity/neutral/N0 */

  background: #ffffff;
  /* anacity/neutral/N30 */
  border-bottom: 1px solid #ecebed;
  margin-left: 24px;
  padding-right: 80px;
  margin-top: 12px;
}

.topNavMainCart {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
  height: 64px;
  top: 80px;
  /* background: #efeff0; */
  border-bottom: 1px solid #ecebed;
  margin-bottom: 20px;
  margin-left: 24px;
  /* padding-right: 80px; */
  /* margin-top: 12px; */
}

.buttonsDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 40px;
}

h3 {
  font-weight: 700;
  font-size: var(--fontSize1);
  line-height: 28px;
  /* identical to box height, or 140% */

  color: #15011e;
}

.cartMainDiv {
  display: flex;
  gap: 5px;
  background: #fff;
  height: 40px;
  padding: 10px 20px;
  color: var(--textColorActive);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-left: -300px;
  cursor: pointer;
  box-sizing: border-box;
}
