.bookingHistoryEventTbl {
  border: 1px solid #ecebec;
  width: 94%;
  margin-left: 24px;
  margin-right: 3px;
  margin-top: 10px;
}

.BHEventtableHead {
  height: 48px;
  border-radius: 2px;
  text-align: start;
}

.BHEventtableHead > th {
  font-weight: 700;
  font-size: var(--fontSize3);
  line-height: 20px;
  color: var(--tblHeadColor);
  padding-left: 12px;
  text-align: start;
  /* word-wrap: break-word;
    max-width: 30px; */
}

.BHEventtableData {
  height: 48px;
  border-radius: 2px;
}

.BHEventtableData > td {
  font-weight: var(--fontWeight3);
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  padding-left: 12px;
  /* word-wrap: break-word;
    max-width: 148px; */
  text-align: start;
}

.actionsTbl {
  /* display: flex;
    cursor: pointer;
     color: var(--fontColorActiveTab2) !important;
    font-weight: var(--fontWeight1) !important;
    font-size: var(--fontSize3) !important;
    line-height: 20px !important; 

width: 100%;
height: 100%; */
}

.actionsTbl > div {
  display: flex;
  cursor: pointer;
  gap: 16px;
  align-items: center;
}

table {
  border-collapse: collapse;
}

th,
td {
  border: 1pt solid var(--borderColor);
}

.waiting {
  color: #ffb752 !important;
  padding: 8px;
  width: 70px;
  height: 36px;
  background: #fff4e3;
  border-radius: var(--borderRadius);
  padding-left: 12px;
}

.booked {
  color: #3eb867 !important;
  padding: 8px;
  width: 72px;
  height: 36px;
  padding-left: 12px;
  background: #ecfff2;
  border-radius: var(--borderRadius);
}

.cancelled {
  color: #ab0000 !important;
  padding: 8px;
  width: 88px;
  height: 36px;
  background: #ffeeee;
  border-radius: var(--borderRadius);
  padding-left: 12px;
}

.tblSearchDiv {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  margin-left: 26px;
  margin-right: 24px;
  margin-top: 41px;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px dashed var(--borderColor);
}

.tblSearchIconDiv {
  display: flex;
  align-items: center;
  gap: 4px;
}

.searchBtnDiv {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #f1f1f1;
  border-radius: var(--borderRadius);
}

.tblExportIconDiv {
  width: 52px;
  height: 40px;
  cursor: pointer;
}

.tblExportIconDiv > img {
  width: 100%;
  height: 100%;
}

.tblExportIconDiv1 {
  width: 169px;
  height: 40px;
  cursor: pointer;
}

.tblExportIconDiv1 > img {
  width: 100%;
  height: 100%;
}

/* ------------------ */
.tblMainDiv {
  /* border: 1px solid black; */
  width: 90vw;
  overflow: auto;
  margin-left: 19px;
  padding: 5px;
}

/* sSearch */
.searchParentDiv {
  padding-left: 24px;
  padding-right: 60px;
  margin-top: 16px;
  margin-bottom: 12px;
}

/* .searchDiv {
    width: 100%;
    height: 40px;
    border: 1px solid #FAFAFA;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: #FAFAFA !important;
    margin-right: 10px;
    flex: 1;
}

.searchDivInput {
  
    border: none;
    outline: none;
    width: 72vw !important;
   
    background: #FAFAFA !important;
}

.searchIcon {
    cursor: pointer;
} */

.bookingSearch {
  height: 72px;
  border-radius: 4px;
  background: #fafafa;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  margin-right: 32px;
}

.statusSelectDiv {
  display: flex;
  align-items: center;
  width: 280px;
  border-radius: 0px 8px 8px 0px;
  background: #fff;
}

/* .statusSelectDiv select {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
} */

/* .statusSelectDiv>div {
    width: 71px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 8px 0px 0px 8px;
    background: #ECEBED;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.selectTitle {
  width: 71px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px 0px 0px 8px;
  background: #ecebed;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectStatus {
  width: 208px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 0px 8px 8px 0px;
  background: #fff;
  border: none;
  padding: 10px 16px;
  outline: none;
}

.searchDiv {
  width: 256px;
  height: 40px;
  /* border: 1px solid lightgray; */
  display: flex;
  align-items: center;
  margin-right: 10px;
  border-radius: 4px;
  background: #fff;
}

.searchDivInput {
  /* width: 100%; */
  border: none;
  outline: none;
  /* width: 72vw !important; */
  /* border: none !important; */
  background-color: #fff !important;
}

.searchIcon {
  cursor: pointer;
}

.dateDiv {
  display: flex;
  align-items: center;
  gap: 16px;
}

.dateDiv > span {
  width: 54px !important;
  height: 40px !important;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  color: #92215c !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dateRangePicker {
  width: 336px !important;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  outline: none !important;
}

.goBtn {
  width: 54px !important;
  height: 40px !important;

  /* flex-shrink: 0;
    border-radius: 8px;
    background: #FFF;
    color: #92215C !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; */
}

/* ------search---- */
.searchDiv {
  width: 256px;
  height: 40px;
  /* border: 1px solid lightgray; */
  display: flex;
  align-items: center;
  margin-right: 10px;
  border-radius: 4px;
  background: #fff;
}

.searchDivInput {
  width: 100%;
  border: none;
  outline: none;
  /* width: 72vw !important; */
  /* border: none !important; */
  background-color: #fff !important;
}

.searchIcon {
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin-top: 32px;
}

.paginationValuesDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #4d5259 !important;
}

.paginationValuesDiv > p {
  color: #4d5259 !important;
}

.pagination > div {
  display: flex;
  gap: 44px;
}

.paginationIconDiv {
  display: flex;
  gap: 30px;
  color: #4d5259 !important;
  cursor: pointer;
}
