.SrearchAndFilterMainDiv {
  box-sizing: border-box;
  padding: 16px 80px 16px 24px;
}

.SrearchAndFilterSubMainDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 35px;
}

.fiterdataDiv {
  display: flex;
  gap: 24px;
  margin-top: 15px;
}

.selectedFilterData {
  display: flex;
  gap: 24px;
}

.selectedFilterData > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: var(--bgcolor);
  border-radius: 4px;
  cursor: pointer;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize4);
  line-height: 16px;
  color: var(--fontColorActiveTab2);
}

.fiterClearDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #c5c1c3;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize4);
  line-height: 16px;
  color: #73696e;
  cursor: pointer;
}

.filterimg {
  width: 184px;
  height: 38px;
  border-radius: 4px;
  outline: red;
}
.searchBar {
  border: none !important;
  background-color: #fafafa !important;
  height: 40px !important;
}
.filterLstMain {
  padding: 24px !important;
}

.filterLst > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #9d9699;
  margin-top: 16px !important;
  margin-bottom: 8px;
}

.applyBtn {
  background-color: #92215c !important;
  border-color: #92215c !important;
}

.filterApply {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  height: 32px;
  background: var(--textColorActive);
  border-radius: 4px;
  border: 1px solid var(--textColorActive);
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  cursor: pointer;
  color: #ffffff;
}

.filterReset {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #c5c1c3;
  border-radius: 4px;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  cursor: pointer;
  color: #73696e;
}

.filterbtnDiv {
  display: flex;
  gap: 24px;
  margin-top: 32px;
}

.drp-buttons {
  display: none !important;
}

.serchIconSearch {
  margin-right: -40px;
  z-index: 3;
  margin-top: 3px;
  /* display: block; */
}

.searchbarDivForIcon {
  display: flex;
  flex: 1;
}
.sbtnid {
  margin-left: 5px;
}

.filterMargin {
  margin-left: 16px;
  margin-right: 26px;
}

.filterMainBtn {
  background-color: #fafafa !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  color: #73696e;
  border: none !important;
  width: 184px;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
}

.serchMainBtn {
  color: #73696e;
}

.ub-color_474d66 {
  color: var(--textColor) !important;
}

.filterDiv {
  display: flex;
  align-items: center;
}

.highlightOption {
  background-color: #92215c !important;
}

.multiSelectContainer li:hover {
  background-color: #de77ac !important;
}
.searchDiv {
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: #fafafa !important;
  margin-right: 10px;
  flex: 1;
}

.searchDivInput {
  width: 100%;
  border: none;
  outline: none;
  background-color: #fafafa !important;
}

.searchIcon {
  cursor: none;
}

.filterDisplayNav {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filterCrossIcon {
  position: absolute;
  top: 3%;
  right: 6%;
  cursor: pointer;
}

/* ----------- */
.filterLeftIconDiv {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.filterLeftIconDiv > img {
  width: 100%;
  height: 100%;
}

.filterRightIconDiv {
  width: 32px;
  height: 32px;
}

.filterRightIconDiv > img {
  width: 100%;
  height: 100%;
}
