.mainDivEventsListItems {
  font-style: normal;
  padding: 16px 16px 16px 24px;
  margin-bottom: 12px;
  /* height: 164px; */
  border: 1px solid var(--borderColor);
  border-radius: var(--borderRadius);
  cursor: pointer;
  margin-right: 10px;
}

.titleDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleDiv > p {
  padding: 4px;
  background: var(--bgColor1);
  border-radius: var(--borderRadius);
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize4);
  line-height: 16px;
  letter-spacing: -0.02em;
  color: var(--fontColorActiveTab1);
}

.titleDiv > h1 {
  font-weight: var(--fontWeight2) !important;
  font-size: var(--fontSize1);
  line-height: 24px;
  color: var(--textColor);
}

.ctaDiv {
  display: flex;
  align-items: center;
  justify-self: center;
  gap: 14px;
  cursor: pointer;
}

.hamsburgerDiv > div > p {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize3);
  line-height: 20px;
}

.popoverDivActive {
  color: var(--textColorActive1) !important;
  background: var(--bgColorActiveTab);
}

.popoverDiv {
  padding: 18px 55px 18px 28px;
  /* border: 1px solid black; */
  color: var(--fontColorNormal4);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 4px;
  gap: 12px;
  cursor: pointer;
}

.tooltipChangeStatus {
  /* box-shadow: 5px 10px 8px #F4E0EB */
  box-shadow: -5px -5px 20px #f4e0eb;
}

.tooltipChangeStatus > p {
  padding-left: 24px;
  padding-top: 17px;
  padding-right: 96px;
  padding-bottom: 17px;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize3);
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
}

.tooltipChangeStatus > p:hover {
  color: var(--textColorActive) !important;
}

.status {
  background: var(--bgColorActiveTab) !important;
  color: var(--textColorActive) !important;
}

.amountDateDivEventsListItems > h5 {
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--fontColorNormal3) !important;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize5);
  line-height: 16px;
  letter-spacing: 0.1px;
}

.amountDateDivEventsListItems > p {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize3);
  line-height: 24px;
  color: var(--textColor);
}

.amountDateValueDivEventsListItems {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.activetab {
  background: var(--bgColorActiveTab);
  border-radius: var(--borderRadius);
}

.ImgEventsListItems {
  margin-top: 12px;
  display: flex;
  gap: 14px;
  align-items: center;
}

.ImgEventsListItems > h1 {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize3);
  line-height: 20px;
  color: var(--textColor);
}

.mainDivEventsListItemsActive {
  font-style: normal;
  padding: 16px 16px 16px 24px;
  margin-bottom: 12px;
  /* height: 164px; */
  border: 1px solid var(--bgColorActiveTab);
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.titleDivActive {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleDivActive > h1 {
  font-weight: var(--fontWeight2) !important;
  font-size: var(--fontSize1);
  line-height: 24px;
  color: var(--fontColorActiveTab1);
}

.titleDivActive > p {
  padding: 4px;
  /* background: var(--bgColor1); */
  border-radius: 4px;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize4);
  line-height: 16px;
  letter-spacing: -0.02em;
  color: var(--fontColorActiveTab1);
}

.statusColorOpen {
  color: #3eb867 !important;
}

.statusColor {
  color: var(--statusClose) !important;
}

.catImg {
  width: 20px;
  height: 20px;
}

.catImg > img {
  width: 100%;
  height: 100%;
}

.timeStatusDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
