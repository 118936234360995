.mainDivEventsList {
  /* width: 40%; */
  /* border: 1px solid black; */
}

.topNavEventsList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-right: 10px;
}

.topNavEventsList > h1 {
  font-weight: var(--fontWeight2) !important;
  font-size: var(--fontSize2) !important;
  line-height: 24px !important;
  color: var(--textColor) !important;
}

.middleDivEventsList {
  height: 55vh;
  /* min-height: calc(60vh - 7rem);  */
  overflow: hidden;
  overflow-y: auto;
  /* margin-left: 45px; */
}

.topNavEventsList > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  color: #121212;
  background: #f4e0eb;
  border-radius: 4px;
  padding: 4px 8px;
}

.monthHeader {
  width: 100%;
  height: 24px;
  background: #fafafa;
  /* border:1px solid black; */
  display: block;
  margin-bottom: 16px;
  padding: auto;
}

.monthHeader > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize3);
  line-height: 18px;
  color: #74676e;
  padding-left: 12px;
  padding-top: 3px;
}
