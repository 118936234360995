.creatEventMainDiv {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* align-items: center; */
  padding-left: 24px;
  padding-right: 80px;
  /* max-width: 122.2rem !important; */
}

.mainDiv {
  /* width: 1252px !important; */
  /* height: 740px; */
  padding-bottom: 40px;
  background: var(--bgColor1);
  border: 1px solid var(--borderColor);
  border-radius: 4px;
}

.titleDiv {
  background: var(--bgcolor);
  border-radius: 4px 4px 0px 0px;
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 4px;
}

.brMargin {
  margin-top: 12px;
}

.titleDiv > p {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 20px;
  color: var(--textColorActive1) !important;
}

.labelFont {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2) !important;
}

.timePickerMainDiv {
  display: flex;
  /* gap: 48px */
  justify-content: space-between;
}

.timePickerMainDiv > div {
  width: 45%;
}

.star {
  color: var(--statusClose);
}

.displayFlexDiv {
  display: flex;
  gap: 80px;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
  justify-content: space-between;
}

.displayFlexDiv > div {
  width: 50%;
}

.inputText {
  margin-top: 12px;
  width: 100% !important;
  height: 44px !important;
}

.inputTextArea {
  width: 100% !important;
  height: 152px !important;
  margin-top: 12px;
}

.radioLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #666666;
}

.radioDiv {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
  /* border: 1px solid #c5c0c7; */
  /* padding: 8px 12px; */
  /* border-radius: 4px; */
}

.radioDivActive {
  display: flex;
  align-items: center;
  gap: 12px;
  /* border: 1px solid var(--fontColorActiveTab1); */
  /* padding: 8px 12px; */
  height: 44px;
  /* border-radius: 4px; */
  color: var(--fontColorActiveTab1);
  /* background-color: var(--bgColorActiveTab); */
}

.radioDivActive > label {
  color: var(--fontColorActiveTab1);
}

.radioMainDiv {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
  width: 100%;
  flex-wrap: wrap;
}

.ImgUploadGuestDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  margin-top: 12px;
  position: relative;
}

.guestAdd {
  /* position: absolute;
    left: 470px;
    top: 12px;  */
  cursor: pointer;
}

.guestImg {
  /* position: absolute;
    right : 69px;
    top: 0 px;  */
  cursor: pointer;
}

.cancellationAllowedDiv {
  border: 1px solid var(--borderColor);
  border-radius: 4px;
  margin-left: 24px;
  margin-right: 24px;
  /* width: 1204px; */
  margin-top: 16px;
  padding-bottom: 15px;
}

.cancellationAllowedflexDiv {
  display: flex;
  gap: 80px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
  /* border: 1px solid #ECEBEC; */
  position: relative;
}

.cancellationAllowedBtn {
  background: var(--bgColor2);
  border-radius: 0px 0px 4px 4px;
  padding: 16px 300px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--textColorActive);
  border: none;
  margin-top: 32px;
  cursor: pointer;
}

.cancellationAllowedflexDiv > div > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--fontColorNormal3);
  margin-bottom: 12px;
}

.removeIconDiv {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  left: 96%;
  top: -8%;
  background: var(--bgColor1);
  cursor: pointer;
  z-index: 4;
}

.removeIconDivSplGuest {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  right: 2%;
  background: var(--bgColor1);
  cursor: pointer;
  z-index: 4;
}
.removeIconDivSplGuestPrice {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  right: 2%;
  background: var(--bgColor1);
  cursor: pointer;
  z-index: 4;
  top: 56%;
}
.removeIconDivSplGuestPriceCategory {
  width: 20px !important;
  height: 20px !important;
  background: var(--bgColor1);
  cursor: pointer;
}
.imgUploadDiv {
  width: 160px;
  height: 160px;
  background: var(--bgColor2);
  border-radius: 4px;
}

.uploadEventImageDiv {
  width: 1204px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  border: 1px dashed #dedfe1;
  border-radius: 4px;
  margin-left: 24px;
  margin-top: 32px;
}

.imgUploadDiv > img {
  width: 100%;
  height: 100%;
}

.imgIconUploadDiv {
  display: flex;
  align-items: center;
  gap: 32px;
}

.browseBtn {
  width: 220px;
  height: 40px;
  border: 1px solid #dedfe1;
  border-radius: 3px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #121212;
  cursor: pointer;
}

.btnCreateEvent {
  margin-bottom: 136px;
  border-top: 1px solid #dedfe1;
  padding-top: 10px;
  /* width: 1252px; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.createBtn {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  width: 160.77px;
  height: 40px;
  background: var(--textColorActive);
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.discardBtn {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: var(--textColorActive);
  width: 160.77px;
  height: 40px;
  background: var(--bgColor1);
  border: 1px solid var(--textColorActive);
  border-radius: 3px;
  cursor: pointer;
}

.dateRangePicker {
  margin-top: 12px !important;
  width: 100% !important;
  z-index: 1000 !important;
}

.chargeNpriceDiv {
  display: flex;
  gap: 25px;
  align-items: center;
}

.specialGuestLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rupee {
  position: absolute;
  top: 42px;
  left: 3%;
  font-size: 19px;
}

.guestInput {
  flex: 1;
}

.chargeNpriceDiv > div {
  width: 50%;
}

.cancellationAllowedflexDiv > div {
  width: 50% !important;
}

/* @media only screen and (max-width: 1000px) {
    .displayFlexDiv {
        display: flex;
        gap: 23px;
        align-items: center;
        padding-left: 24px;
        padding-right: 24px;
        margin-top: 32px;
    }
} */
.textEventCountDiv {
  text-align: right;
}
.eventMainOuterLimitDiv {
  display: flex;
  color: red;
}
.eventMaxCountText {
  width: 75%;
}
.eventMaxLimitCountNumber {
  width: 25%;
  text-align: right;
}
.seatingCategorizationMainDiv {
  border-radius: 4px;
  border: 1px solid #ecebec;
  margin-left: 24px;
  margin-top: 24px;
  margin-right: 24px;
}
.seatingCategorizationDiv {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 24px;
  padding-right: 24px;
  padding-right: 24px;
}
.seatingCategorizationDiv > div {
  display: flex;
  gap: 14px;
  border-radius: 4px;
  background: #fafafa;
  padding: 24px;
  margin-top: 24px;
}
.seatingCatName {
  margin-top: 23px;
  word-break: break-all;
  color: #121212;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.seatingRemove {
  position: absolute;
  right: -3px;
  top: -9px;
}
.seatingLabel {
  color: #74676e;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.seatingInput {
  border: 1px solid #dedfe1;
  height: 40px;
  outline: none;
  border-radius: 4px;
  width: 180px;
  padding-left: 10px;
}

.pricePerCategoryDiv {
  border: 1px solid #ecebec;
  border-radius: 4px;
  /* min-height: 300px; */
  margin: 24px;
}
.pricePerCategoryTitle {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
.pricePerCategoryContent {
  margin-top: 13px;
  margin-bottom: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  /* border: 1px solid green; */
}
.pricePerCategoryContent > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 260px;
  height: 40px;
  /* border: 1px solid red; */
  margin-top: 12px;
  margin-right: 30px;
}
.pricePerCategoryLabel {
  color: #74676e;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.seatingCategorizationWatingDiv {
  display: flex;
  justify-content: space-between;
}
.seatingCategorizationWatingDiv > div {
  width: 50%;
}

.customDropdown {
  max-height: 200px; /* Set a fixed height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
}
.addGuestPrice {
  color: #448eb9;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  background: #fafafa;
  width: 140px;
  width: 45%;
  padding: 10px;
  margin-top: 28px;
  border-radius: 5px;
  cursor: pointer;
}
.venueLabelContainer {
  display: flex;
  justify-content: space-between;
  padding-right: 4px;
}
.registrationDynamicDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 14px;
  padding-right: 14px;
}
.registrationDynamicDiv > div {
  width: 46%;
  margin-bottom: 28px;
}
.userForm {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 20px;
  color: var(--textColorActive1) !important;
  padding-top: 40px;
  padding-left: 14px;
  padding-bottom: 30px;
}
.labelRegDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.regCheckboxMainDiv {
  display: flex;
  align-items: center;
  gap: 5px;
}
.displayFlexDivSecondry {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selectFormDiv {
  width: 70% !important;
}
.displayFlexDiv2 {
  display: flex;
  gap: 80px;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
  justify-content: space-between;
}
.displayFlexDiv3 {
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 24px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.displayFlexDiv3 > div {
  background: #f9f9f9;
  width: 45%;
  padding: 16px;
  margin-top: 10px;
}
.addBtnSecondry {
  color: #448eb9;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  background: #fafafa;
  /* width: 140px;
  width: 45%; */
  padding: 10px;
  margin-top: 28px;
  border-radius: 5px;
  cursor: pointer;
}
.detailItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  border-radius: 4px;
}
.detailItem > p {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #4d4d4f;
}
.selectedAudienceFormDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 24px;
}
.selectedAudienceLabel {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #5798c0;
  height: 24px;
}
.mandatoryDiv {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4d4d4f;
}
.saveBtn {
  display: flex;
  justify-content: end;
  padding-bottom: 25px;
  gap: 10px;
}
.formIcon {
  width: 20px;
  height: 20px;
}
.formIcon > img {
  width: 100%;
  height: 100%;
}
.venueLabelContainer {
  display: flex;
  justify-content: space-between;
  padding-right: 4px;
}
