.dataContainer {
  background-color: #fafafa;
}
.formDiv {
  width: 640px;
  height: 200px;
  border-radius: 4px 0px 0px 0px;
  border: 1px solid #dedfe1;
  padding: 24px;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.formMainContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.mandatory {
  color: var(--statusClose);
}

.titleDiv {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #121212;
}
.contentDiv {
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
}
.controllerContainer {
  width: 100%;
  box-shadow: 0px -2px 2px 0px #dedfe180;
  padding: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.inputDiv {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
.inputDiv > label {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.inputComponent {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid var(--borderColor3);
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 12px;
  color: var(--fontColorNormal2);
  height: 40px;
}
.inputComponent:focus {
  outline: none;
}
.mainContainer {
  display: flex;
  gap: 32px;
}
.imgDiv {
  border: 1px solid var(--borderColor3);
  height: 245px;
  width: 230px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageSelectorDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sideDiv {
  width: 232px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.avatarDiv {
  height: 180px;
  width: 180px;
  border-radius: 100%;
  position: relative;
}
.cameraIconDiv {
  position: absolute;
  text-align: center;
  right: 29px;
  bottom: 0px;
  background: var(--bgColor2);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatarName {
  font-size: 60px;
  font-weight: 500;
  line-height: 37.5px;
  letter-spacing: -0.02em;
}
.invalidField {
  border: 1px solid var(--statusClose);
  border-radius: 4px;
}
