.mainDivBookingHistory {
  width: 100%;
}
.tblMainDiv {
  box-sizing: border-box;
  overflow: auto;
  padding-top: 5px;
  padding-left: 26px !important;
  width: 100%;
  padding: 5px;
  padding-right: 35px;
}
.activeTabDiv {
  display: flex;
  align-items: center;
  padding-left: 23px;
  gap: 20px;
  padding-left: 23px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.activeTabDiv > span {
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}
.activeTab {
  color: var(--textColorActive);
  border-bottom: 1px solid var(--textColorActive);
  padding-left: 10px;
  padding-right: 10px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin-top: 32px;
}

.paginationValuesDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #4d5259 !important;
}

.paginationValuesDiv > p {
  color: #4d5259 !important;
}

.pagination > div {
  display: flex;
  gap: 44px;
}

.paginationIconDiv {
  display: flex;
  gap: 30px;
  color: #4d5259 !important;
  cursor: pointer;
}
.excelDiv {
  border: 1px solid var(--borderColor);
  border-radius: 5px;
  cursor: pointer;
}

.searchParentDiv {
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 16px;
  margin-bottom: 12px;
  display: flex;
  justify-content: end;
}

.searchDiv {
  width: 85%;
  height: 40px;
  border: 1px solid var(--bgColor2);
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: var(--bgColor2) !important;
  margin-right: 10px;
  flex: 1;
}

.searchDivInput {
  border: none;
  outline: none;
  width: 72vw !important;
  background: var(--bgColor2) !important;
}

.searchIcon {
  cursor: pointer;
}
