.btn {
  padding: 10px 20px;
  border-radius: 3px;
  background: var(--textColorActive);
  border-radius: 3px;
  color: var(--anacity-neutral-n-0, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
