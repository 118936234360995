.bookHeaderDiv {
  padding: 40px 40px 10px 40px;
  font-size: 18px;
  font-weight: 700;
}

.bookHeaderDiv > div:first-child {
  padding-bottom: 10px;
}

.bookHeaderDiv > div:last-child {
  color: rgba(134, 134, 134, 1);
}

.borderBottom {
  margin: 0px 40px 0px 40px;
  border-bottom: 1px solid rgba(236, 235, 237, 1);
}

.scrollDiv {
  height: 50vh;
  overflow-y: auto;
  padding: 20px 40px 0px 40px;
}

.borderDiv {
  box-sizing: border-box;
  padding: 24px 24px 0px 24px;
  border-radius: 4px;
  border: 1px solid rgba(222, 223, 225, 1);
  min-width: 690px;
}

.LLabel {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px !important;
  color: rgba(18, 18, 18, 1);
}

.btnDivBookEventDetail {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 32px;
  margin-bottom: 32px;
  /* border: 2px solid green; */
}

::placeholder {
  font-style: normal !important;
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize4) !important;
  line-height: 16px !important;
  color: #b7b1b4 !important;
  margin-left: 8px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.nameDiv {
  position: absolute;
  top: 47px;
  right: 13px;
  z-index: 3;
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize3);
  line-height: 18px;
  color: var(--fontColorNormal4);
}

.timeDiv {
  display: flex;
  flex-direction: column;
  /* margin-top: 32px; */
  gap: 16px;
}

.timerInput {
  margin-top: 14px;
  /* width: 254px; */
  margin-bottom: 24px;
}

.slotDiv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  width: 630px;
  padding-top: 12px;
  padding-bottom: 10px;
}

.slotDiv > div {
  display: flex;
  justify-content: center;
  gap: 20px;
  background: #ffffff;
  border: 1px solid #ecebed;
  border-radius: 16px;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize4);
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #868686;
  padding: 8px 13px;
  /* margin-top: 12px; */
  cursor: pointer;
  width: 140px;
}

.slotDivService {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  width: 630px;
  padding-top: 12px;
  padding-bottom: 10px;
}

.slotDivService > p {
  background: #ffffff;
  border: 1px solid #ecebed;
  border-radius: 16px;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize4);
  line-height: 14px;
  text-align: left;
  letter-spacing: -0.02em;
  color: #868686;
  padding: 4px 15px;
  /* margin-top: 12px; */
  cursor: pointer;
  width: 140px;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
}

.timeColor {
  color: var(--fontColorNormal2) !important;
}

.timeSelected {
  color: #92215c !important;
  background-color: #f4e0eb !important;
  border: 1px solid #92215c !important;
  font-weight: 600;
  font-size: 16px;
}

.amountCard {
  display: flex;
  flex-direction: column;
  padding: 24px 15px 24px 16px;
  justify-content: space-between;
  align-items: space-between;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fafafa;
  margin: 22px 0px 22px 0px;
}

.flexJustify {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.amountText {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: end;
}

.discountCont {
  display: flex;
  gap: 4px;
}

.originalCost {
  color: #bebebe;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: -0.24px;
  text-decoration: line-through;
}

.discount {
  color: #ab0000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: -0.24px;
}
.nameInputCont {
  width: 620px;
}

/* .cancelPolicyDiv {
  width: 725px;
}

.cancelPolicyDiv > div:first-child {
  color: rgba(134, 134, 134, 1);
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
}

.cancelPolicyDiv > div:nth-child(2) {
  color: rgba(134, 134, 134, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 20px;
}

.cancelPolicyDiv > div:nth-child(3) {
  color: rgba(18, 18, 18, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.inputDivTC {
  display: flex;
  gap: 10px;
} */

/* trainer implementation css */

.trainerContainerDiv {
  display: flex;
  gap: 16px;
  overflow-x: scroll;
  padding-top: 12px;
  padding-bottom: 24px;
}

.trainerContainerDiv::-webkit-scrollbar {
  display: none;
}

.inputDiv {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.trainerLabel {
  font-weight: 500;
}

.trainerDiv {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 64px;
  /* width: 85px;
  align-items: center;
  border: 1px solid rgba(146, 33, 92, 1);
  border-radius: 4px;
  padding: 5px 15px; */
}
.imageDiv {
  height: 64px;
  width: 64px;
  flex-shrink: 0;
  background: #f5ecf9;
  position: relative;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkBox {
  position: absolute;
  top: -10px;
  right: -4px;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  fill: #fff;
  stroke-width: 1px;
  stroke: #868686;
}
.trainerName {
  color: var(--textColor);
  text-align: center;
  word-break: break-word;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.2px;
}

.inputDivTC {
  display: flex;
  gap: 10px;
  margin-bottom: 22px;
}

.packagesCont {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
}

.packageCheckbox {
  padding: 16px 24px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(248, 236, 243, 1);
  border: 1px solid rgba(219, 219, 219, 1);
  font-size: 12px;
  font-weight: 600;
  color: rgba(18, 18, 18, 1);
}
.checkboxLeftCont {
  display: flex;
  gap: 16px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.noProfText {
  color: #ab0000;
}
