.mainDivConceirge {
  width: 100%;
}
.tabOuterDiv {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.directoryHead {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 60px 24px 20px;
  border-bottom: 1px solid var(--borderColor);
}
.directorySearch {
  height: 72px;
  border-radius: 4px;
  background: #fafafa;
  margin: 24px 32px;
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 16px 24px;
}
.searchDiv {
  /* width: 256px; */
  height: 40px;
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 10px;
  border-radius: 4px;
  background: #fff;
}
.searchDivInput {
  width: 90%;
  border: none;
  outline: none;
  background-color: #fff !important;
}
.departmentSelectDiv {
  display: flex;
  align-items: center;
  width: 250px;
  border-radius: 0px 8px 8px 0px;
  background: #fff;
}
.selectTitle {
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px 0px 0px 8px;
  background: #ecebed;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
.tableHead {
  height: 52px;
  border-radius: 4px !important;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.12);
  padding: 16px;
  border: 1px solid black;
  top: 0;
  position: sticky;
}

.tableHead > th {
  color: #767c82;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 20px;
  /* min-width: 140px; */
  border: none !important;
  text-align: start;
  padding-left: 16px;
}
.tableData {
  height: 52px !important;
  width: 100%;
  flex-shrink: 0;
  border-radius: 4px !important;
  background: #fafafa !important;
  border: 1px solid black;
  margin-top: 10px !important;
}

.tableData > td {
  color: #121212;
  font-size: 14px;
  font-family: Poppins;
  line-height: 20px;
  text-align: start;
  border: none !important;
  padding-left: 16px;
  min-width: 260px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin-top: 32px;
}

.paginationValuesDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #4d5259 !important;
}

.paginationIconDiv > p {
  color: #4d5259 !important;
}
.pagination > div {
  display: flex;
  gap: 44px;
}

.paginationIconDiv {
  display: flex;
  gap: 30px;
  color: #4d5259 !important;
  cursor: pointer;
}
.bookedFor {
  color: var(--textColorActive) !important;
  cursor: pointer;
}
.noDataMainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noDataDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.noDataDiv > span {
  width: 170px;
  height: 170px;
}

.noDataDiv > span > img {
  width: 100%;
  height: 100%;
}

.noDataDiv > h2 {
  color: #121212;
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  padding-left: 28px;
}

.noDataDiv > p {
  color: #74676e;
  font-size: 24px;
  line-height: 28px;
  margin-top: 16px;
}