.viewBookingMainDiv {
  height: 64vh;
  width: 830px;
}

.viewBookingPaddingDiv {
  padding: 0px 50px 20px 50px;
}

.topDiv {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 10px;
  padding: 10px 20px;
  background-color: rgba(250, 250, 250, 1);
  border-radius: 4px;
}

.navDiv {
  margin-left: 29px;
  margin-top: 12px;
}

.inputDivMain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize3);
  line-height: 20px;
  width: 200px;
  padding: 5px 0px 5px 0px;
  /* flex:1 */
}

.inputDiv {
  border-radius: 4px;
  padding-top: 8px;
  font-weight: var(--fontWeight2);
  font-size: 14px;
  line-height: 20px;
  color: var(--fontColorNormal2);
}

.bottomDiv {
  border: 1px solid rgba(222, 223, 225, 1);
  border-radius: 4px;
}

.bottomOuterDiv {
  padding: 0px 50px 90px 50px;
  /* overflow: auto; */
  /* height: 300px; */
}

.topGreyDiv {
  height: 50px;
  padding: 10px 20px;
  background-color: rgba(250, 250, 250, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.removeDiv {
  height: 30px;
  width: 180px;
  background-color: white;
  border: 1px solid rgba(222, 223, 225, 1);
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(18, 18, 18, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.inpCheckBox {
  height: 25px;
  width: 25px;
}

.checkBoxFlex {
  display: flex;
  /* justify-content: space-between; */
  gap: 24px;
  align-items: flex-start;
}

.cardDiv {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 20px;
}

.btnDiv {
  display: flex;
  justify-content: center;
  padding: 20px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: white;
  box-shadow: 0px -17px 7px rgba(49, 42, 29, 0.01),
    0px -9px 6px rgba(49, 42, 29, 0.03), 0px -4px 4px rgba(49, 42, 29, 0.04),
    0px -1px 2px rgba(49, 42, 29, 0.05), 0px 0px 0px rgba(49, 42, 29, 0.05);
}

.serviceCardDiv {
  border: 1px solid rgba(222, 223, 225, 1);
  border-radius: 4px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 20px;
}
.bookedForDiv {
  padding: 16px 32px;
  background-color: var(--bgColor2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.serviceImgDiv {
  height: 150px;
  width: 188px;
}
.serviceImgDiv > img {
  height: 100%;
  width: 100%;
}
.contentMainDiv {
  padding-bottom: 60px;
  /* overflow:scroll; */
}
.infoColDiv {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.infoRows {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.outerDiv {
  display: flex;
  gap: 15px;
  width: 100%;
}
.bookigDetailsDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

.infoMainDiv {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  gap: 25px;
  align-items: center;
}
.alignmentDiv {
  width: 40%;
}
/* .infoDiv div,
.infoDivTwo div {
  padding-bottom: 12px;
} */
.infoDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
}

.infoDiv > div {
  color: rgba(134, 134, 134, 1);
}
/* .statusDiv {
  width: 100%;
  float: left;
} */

.infoDivTwo > div {
  color: rgba(18, 18, 18, 1);
}
.colorBlack {
  color: rgba(18, 18, 18, 1);
}
.bookedService {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1rem;
  height: 30px;
  width: 70px;
  color: rgba(62, 184, 103, 1);
  background-color: rgba(236, 255, 242, 1);
}
.cancelledService {
  color: rgba(171, 0, 0, 1);
}
.reasonForCancelDiv {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.reasonForCancelDiv > Textarea {
  height: 160px;
}
.viewDetailsModalMain {
  height: 460px;
  overflow: scroll;
  padding-bottom: 70px;
}
.titleDiv {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.waitingService {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1rem;
  height: 30px;
  width: 70px;
  color: rgba(255, 183, 82, 1);
  background-color: rgba(255, 244, 227, 1);
}
.bookingRow {
  display: flex;
  justify-content: space-between; /* Ensure divs are evenly spaced */
  /* padding-bottom: 10px;  */
}
.colorBlack {
  color: var(--fontColorNormal2);
  font-weight: 500;
}
.disabledBackground {
  background-color: #f2f2f2;
}
.customSelectBorder {
  border: 1px solid #690a3c !important;
  border-radius: 4px;
  padding: 4px, 10px, 4px, 10px;
}
