.paddingDiv {
  padding: 26px 26px 10px 26px;
  height: 550px;
  overflow-y: auto;
  width: 93%;
  background: rgba(250, 250, 250, 1);
  margin: 20px;
  border: 1px solid rgba(222, 223, 225, 1);
  border-radius: 4px;
}

.scrollDiv {
  height: 100%;
  margin-right: -26px;
}

.topDiv {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.leftDiv {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.leftTopDiv {
  border: 1px solid rgba(222, 223, 225, 1);
  height: 220px;
  width: 200px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.imgDiv {
  height: 100%;
  width: 230px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.avatarDiv {
  height: 180px;
  width: 180px;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarName {
  font-size: 60px;
  font-weight: 500;
  line-height: 37.5px;
  letter-spacing: -0.02em;
  color: rgba(89, 20, 56, 1);
}

.cameraIconDiv {
  position: absolute;
  text-align: center;
  right: 29px;
  bottom: 0px;
  background: var(--bgColor2);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageSelectorDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.imgDiv {
  height: 100%;
  width: 230px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.avatarDiv {
  height: 180px;
  width: 180px;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarName {
  font-size: 60px;
  font-weight: 500;
  line-height: 37.5px;
  letter-spacing: -0.02em;
  color: rgba(89, 20, 56, 1);
}

.cameraIconDiv {
  position: absolute;
  text-align: center;
  right: 29px;
  bottom: 0px;
  background: var(--bgColor2);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageSelectorDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.imgDiv {
  height: 100%;
  width: 230px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.avatarDiv {
  height: 180px;
  width: 180px;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarName {
  font-size: 60px;
  font-weight: 500;
  line-height: 37.5px;
  letter-spacing: -0.02em;
  color: rgba(89, 20, 56, 1);
}

.cameraIconDiv {
  position: absolute;
  text-align: center;
  right: 29px;
  bottom: 0px;
  background: var(--bgColor2);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageSelectorDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.leftBottomDiv {
  border: 1px solid rgba(222, 223, 225, 1);
  border-radius: 4px;
  height: 230px;
  width: 200px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightDiv {
  width: 890px;
}

.detailsDiv {
  margin-top: 10px;
  border: 1px solid rgba(222, 223, 225, 1);
  border-radius: 4px;
  width: 890px;
  height: 247px;
  padding: 12px 20px;
  margin-bottom: 10px;
  background: white;
}

.displayFlexDiv {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}

.displayFlexDiv > label {
  padding-bottom: 10px;
}

.labelFont {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2) !important;
}

.star {
  color: var(--statusClose);
}

.inputText {
  height: 35px !important;
  margin-top: 5px;
}

.customSelectDesignation {
  width: 400px;
  height: 35px !important;
  margin-top: 5px;
}

.dropdownModules {
  margin-top: 5px !important;
  width: 100% !important;
}

.filterLstMain {
  width: 100%;
  height: 324px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profListPaddingSelectAll {
  margin: 0px 0px 10px 0px;
  border-radius: 4px;
  width: 91%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.singleProfSelectAll {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
}

.outerModuleDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.profListPadding {
  margin-bottom: 10px;
  border-radius: 4px;
  width: 91%;
}

.singleProf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
}

.userRightsCruds {
  border-radius: 4px;
  height: 100px;
  padding-top: 10px;
}

.userRightOuterDiv {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.crudDiv {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(222, 223, 225, 1);
  border-radius: 4px;
  width: 48%;
  height: 35px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: rgba(18, 18, 18, 1);
  padding: 15px;
}

.btnDiv {
  border-top: 1px solid #dedfe1;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 24px;
}
