.singleDishMainDiv {
  width: 100%;
  height: 72px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ecebec;
  margin-bottom: 20px;
}
.singleDishImgDiv {
  position: relative;
  width: 80px;
  height: 56px;
  border-radius: 4px;
  overflow: hidden;
  margin-left: 10px;
  /* background-image: url("https://images.pexels.com/photos/1633578/pexels-photo-1633578.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"); */
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #d9d9d9;
}

.singleDishAllignDiv {
  height: 72px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.singleDishAllignDiv:hover {
  background: #f2f2f2;
  cursor: pointer;
}

.singleDishInfoDivPadding {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.singleDishInfoName {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  flex: 0 1 auto;
}
.itemDescription{
 display: flex;
 gap:20px;
 align-items: center;
}
.togglePadding{
  padding:0px 12px;
}