.mainDivBookingHistory {
  width: 100%;
}

.tblMainDiv {
  /* border: 1px solid black; */
  width: 91vw;
  overflow: auto;
  margin-left: 19px;
  padding: 5px;
  padding-right: 20px;
}
