.mainDivBookingHistory {
  width: 100%;
}
.tblMainDiv {
  box-sizing: border-box;
  overflow: auto;
  padding-top: 5px;
  padding-left: 26px !important;
  width: 100%;
  padding: 5px;
  padding-right: 35px;
}
.activeTabDiv {
  display: flex;
  align-items: center;
  padding-left: 23px;
  gap: 20px;
  padding-left: 23px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.activeTabDiv > span {
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}
.activeTab {
  color: var(--textColorActive);
  border-bottom: 1px solid var(--textColorActive);
  padding-left: 10px;
  padding-right: 10px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin-top: 32px;
}

.paginationValuesDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #4d5259 !important;
}

.paginationValuesDiv > p {
  color: #4d5259 !important;
}

.pagination > div {
  display: flex;
  gap: 44px;
}

.paginationIconDiv {
  display: flex;
  gap: 30px;
  color: #4d5259 !important;
  cursor: pointer;
}
.actionDiv {
  display: flex;
  justify-content: end;
  padding-right: 32px;
}
.excelDiv {
  border: 1px solid var(--borderColor);
  border-radius: 5px;
  cursor: pointer;
  margin-top: 4px;
  margin-right: 2px;

}
