.instructionsEventsDetailsCard {
  margin-bottom: 24px;
  white-space: pre-wrap;
}

.middleDivEventsDetailsCard {
  border-radius: 4px;
}

.instructionsEventsDetailsCard p {
  width: 80%;
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  letter-spacing: -0.02em;
  color: var(--textColor);
  padding: 10px 0px 0px 80px;
  word-break: break-word;
}

.basicInfoTopImgEventsDetailsCard {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 50px;
  margin-top: 16px;
}

.basicInfoTopImgEventsDetailsCard > div {
  width: 16px;
  height: 16px;
}

.basicInfoTopImgEventsDetailsCard > div > img {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}

.basicInfoTopImgEventsDetailsCard h6 {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize2);
  line-height: 24px;
  color: var(--fontColorNormal4) !important;
}
.generalInfoDivEventsDetailsCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 80px 10px 80px;
}

.generalInfoDivEventsDetailsCard > div > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize4);
  line-height: 16px;
  letter-spacing: 0.1px;
  text-align: left;
  margin-bottom: 4px;
  color: var(--fontColorNormal3);
  margin-top: 16px;
}

.generalInfoDivEventsDetailsCard > div > div {
  font-weight: var(--fontWeight3);
  font-size: var(--fontSize2);
  line-height: 20px;
  color: var(--textColor);
  margin-top: 0px;
  text-align: left;
}
.btnDiv {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 55px;
}
