.topHeading {
  padding: 18px 24px 18px 48px;
  color: #121212;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.borderBottom {
  border: 1px solid rgba(236, 235, 237, 1);
  margin: 0px 24px 24px;
}

.leftRightOuterDiv {
  display: flex;
  gap: 24px;
}

.leftDivPadding {
  width: 65%;
  padding: 0px 0px 0px 24px;
}

.leftCartBox {
  border-radius: 4px;
}

.searchTabsListDiv {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
}

.searchBarMainDiv {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 40px;
}

.searchBarMainDivPadding {
  flex: 1;
  height: 40px;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid var(--borderColor3);
  padding: 10px 16px;
}

.searchBarMainDivPadding > input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}

.searchIconDiv {
  display: flex;
  width: 48px;
  align-items: flex-start;
  gap: 10px;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
}

.searchIconDiv > img {
  height: 100%;
  width: 100%;
}

.tabsList {
  display: flex;
  height: 45px;
  /* padding-right: 24px; */
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  max-width: 600px;
  overflow-x: scroll;
}

.tabsList::-webkit-scrollbar {
  display: none;
}

.activeCat {
  background: rgba(244, 224, 235, 1);
  color: var(--fontColorActiveTab2);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.36px;
  padding: 4px 12px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(14px);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.activeCat:hover {
  cursor: pointer;
}

.categoryCard:hover {
  cursor: pointer;
}

.categoryCard {
  background: rgba(250, 250, 250, 1);
  padding: 4px 12px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(14px);
  flex: none;
  order: 0;
  flex-grow: 0;
  font-size: 14px;
  font-weight: 400;
}

.topLeftDiv {
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(18, 18, 18, 0.12);
  margin-bottom: 20px;
}

.bottomLeftDiv {
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(18, 18, 18, 0.12);
}

.cardsScrollDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  overflow-y: scroll;
  height: 60vh;
  padding: 0px 24px 24px;
}

.rightCartBox {
  width: 31%;
  padding: 0px 0px 50px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.topRightDiv {
  padding: 10px 20px 10px 20px;
  background: rgba(242, 242, 242, 1);
  border-radius: 4px;
  width: 395px;
}

.selectedServicesDiv {
  display: flex;
  gap: 10px;
  overflow-x: auto;
}

.addressContainerDiv {
  display: flex;
  flex-direction: column;
}

.radioSelectorDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  color: #868686;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.03em;
  cursor: pointer;
}

.isRadioActive {
  color: #92215c;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.03em;
}

/* .typeDiv {
  display: flex;
  height: 40px;
  border: 1px solid #dedfe1;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(18, 18, 18, 0.12);
} */
/* 
.typeAddressDetailsDiv {
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(18, 18, 18, 0.12);
} */

/* .displayFlexDiv {
  height: 40px;
  border: 1px solid #dedfe1;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(18, 18, 18, 0.12);
  margin-bottom: 16px;
} */

.radioMainDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.radioDiv {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
}
.radioDivActive {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  color: var(--fontColorActiveTab1);
}

.radioDivActive > label {
  color: var(--fontColorActiveTab1);
}

.inputText {
  margin-top: 12px;
  height: 40px !important;
}
.bookingSearch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  padding: 0px 0px 0px 8px;
  margin: 24px 24px 15px 24px;
  border: 1px solid var(--borderColor3);
}

.bookingSearch > input {
  height: 40px;
  border: none;
  outline: none;
  width: 100%;
}
.searchIcon {
  width: 32px;
  height: 40px;
}

.searchIcon > img {
  height: 100%;
}

.LLabel {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize2) !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2);
  font-weight: var(--fontWeight3);
}

.mgbottominputSB {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ecebec !important;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 12px 0px;
}

.mgbottominputSBMembershipContact {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ecebec !important;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 12px 0px;
}

.mobileGenderDiv {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px 5px 24px;
}

.menu {
  margin-top: 98px;
  background: #ffffff;
  position: absolute;
  top: 160px;
  left: 47px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 1;
  width: 750px;
}
.menu.active {
  z-index: 1;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}

.menu li:hover {
  background: #f4e0eb;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

.bottomRightDiv {
  box-shadow: 0px 2px 8px 0px rgba(18, 18, 18, 0.12);
  height: 72vh;
}

.rightBottomDetailsDiv {
  color: #868686;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-top: 24px;
  padding-left: 24px;
}

.rightBottomAddressDiv {
  background: #fff;
  height: 246px;
}

.rightBottomAddressDiv > div:first-child {
  padding: 15px 24px 12px;
}

.numOfItems {
  color: #868686;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.flexDivPrice {
  display: flex;
  justify-content: space-between;
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.flexDiv {
  display: flex;
  justify-content: space-between;
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 9px 24px;
}

.greenPackage {
  color: rgba(54, 171, 108, 1);
  font-size: 14px;
  font-weight: 400;
  padding: 0px 24px;
}

.flexDivGrandTotal {
  display: flex;
  justify-content: space-between;
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 15px 24px 26px 24px;
}

.cancellationPolicy {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 0px 24px 0px 24px;
}

.star {
  color: var(--statusClose);
}

.inputDivTC {
  display: flex;
  gap: 10px;
  padding-bottom: 5px;
}

.btnDiv {
  display: flex;
  justify-content: center;
  padding: 24px;
}
