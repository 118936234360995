.buttonsborderedmd {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var( --textColorActive);
  padding: 10px 20px 10px 20px;
  background: #ffffff;
  border-radius: 3px;
  font-weight: var(--fontWeight1);
  font-size: 13px;
  line-height: 20px;
  color: var(--textColorActive);
  cursor: pointer;
}