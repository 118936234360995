.scrollDiv {
  overflow-y: auto;
  height: 40vh;
}

.profBookingCardInnerDiv {
  margin: 20px 15px 10px;
}

.profBookingCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profDateDiv {
  padding-bottom: 20px;
  color: rgba(146, 33, 92, 1);
  font-size: 16px;
  font-weight: 500;
}

.greyCardDiv {
  border-left: 2px solid rgba(62, 184, 103, 1);
  height: 80px;
  padding: 10px 20px;
  background: rgba(250, 250, 250, 1);
  font-size: 12px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0px 4px 4px 0px;
}

.greyCardDiv > div:first-child,
.greyCardDiv > div:nth-child(2),
.greyCardDiv > div:nth-child(3) {
  padding: 3px;
}
