.cartGreyDiv {
  background: #efeff0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.topHeading {
  padding: 18px 24px;
  color: #121212;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.leftRightOuterDiv {
  display: flex;
  justify-content: space-between;
}

.leftCartBox {
  width: 57%;
  padding: 0px 0px 0px 24px;
}

.topLeftDiv {
  display: flex;
  flex-direction: column;
}

.leftTopDetailsDiv {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 18px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.topCardsDiv {
  margin-bottom: 32px;
}

.cardContainerDiv {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.cardContainerDiv > div {
  border-radius: 4px;
  border: 1px solid #dedfe1;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(18, 18, 18, 0.12);
  height: 120px;
}

.cardContainerEmptyDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: #92215c;
  height: 200px;
}

.bottomLeftDiv {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 50px;
  position: relative;
}

.leftBottomDetailsDiv {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 18px;
}

.bottomCardsDiv {
  display: flex;
  gap: 10px;
  width: min(100%, 800px);
  max-width: 700px;
  overflow-x: scroll;
  height: 180px;
}

.imgDetailsContainer {
  width: 190px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
}

.imgDiv {
  width: 190px;
  height: 140px;
  box-sizing: border-box;
  border: 1px solid var(--borderColor3);
  /* position: relative; */
}

/* .image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
} */

.imgDetailsContainer > div {
  color: #121212;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

.imgDetailsDiv {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 5px;
}

.imgDetailsDiv > div > p:first-child {
  padding-bottom: 5px;
}
.imgScrollRightIcon {
  position: absolute;
  bottom: 120px;
  left: 635px;
}

.rightCartBox {
  width: 35%;
  padding: 0px 50px 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rightTopDetailsDiv {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 18px;
}

.addressContainerDiv {
  display: flex;
  flex-direction: column;
}

.radioSelector {
  height: 52px;
  background: #fff;
  border-radius: 2px;
  filter: drop-shadow(0px 2px 8px rgba(18, 18, 18, 0.12));
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 24px;
}

.radioSelectorDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  color: #868686;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.03em;
  cursor: pointer;
}

.isRadioActive {
  color: #92215c;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.03em;
}

.typeDiv {
  display: flex;
  height: 40px;
  border: 1px solid #dedfe1;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(18, 18, 18, 0.12);
}

.typeAddressDetailsDiv {
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(18, 18, 18, 0.12);
}

.displayFlexDiv {
  height: 40px;
  border: 1px solid #dedfe1;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(18, 18, 18, 0.12);
  margin-bottom: 16px;
}

.radioMainDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.radioDiv {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
}
.radioDivActive {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  color: var(--fontColorActiveTab1);
}

.radioDivActive > label {
  color: var(--fontColorActiveTab1);
}

.inputText {
  margin-top: 12px;
  height: 40px !important;
}
.bookingSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-radius: 4px;
  margin: 12px 24px;
}

.bookingSearch > input {
  background-color: #fafafa;
  height: 40px;
  border: none;
  outline: none;
  width: 280px;
}
.searchIcon {
  width: 32px;
  height: 40px;
}

.searchIcon > img {
  height: 100%;
}

.LLabel {
  font-weight: var(--fontWeight3) !important;
  font-size: var(--fontSize2) !important;
  line-height: 20px !important;
  color: var(--fontColorNormal2);
  font-weight: var(--fontWeight3);
  margin: 12px 24px;
}

.mgbottominputSB {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ecebec !important;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 12px 24px;
}

.mgbottominputSBMembershipContact {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ecebec !important;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 12px 24px;
}

.mobileGenderDiv {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  margin-top: 98px;
  background: #ffffff;
  position: absolute;
  top: 60px;
  right: 59px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 1;
}
.menu.active {
  z-index: 1;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}

.menu li:hover {
  background: #f4e0eb;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

.rightBottomDetailsDiv {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-top: 24px;
  padding-bottom: 18px;
}

.rightBottomAddressDiv {
  background: #fff;
  height: 300px;
}

.rightBottomAddressDiv > div:first-child {
  padding: 24px 24px 12px;
}

.rightBottomAddressDivTakeaway {
  background: #fff;
  height: 270px;
}

.rightBottomAddressDivTakeaway > div:first-child {
  padding: 24px 24px 12px;
}

.numOfItems {
  color: #868686;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.flexDivPrice {
  display: flex;
  justify-content: space-between;
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.flexDiv {
  display: flex;
  justify-content: space-between;
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 6px 24px;
}

.flexDivGrandTotal {
  display: flex;
  justify-content: space-between;
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 22px 24px;
}

.btnDiv {
  display: flex;
  justify-content: center;
  padding: 5px;
}
