.topNavMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 0px;
  gap: 76px;
  height: 64px;
  left: 120px;
  top: 80px;
  background: #ffffff;
  border-bottom: 1px solid #ecebed;
  margin-left: 24px;
  padding-right: 80px;
  margin-top: 12px;
}

.buttonsDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
  height: 40px;
}

h3 {
  font-weight: 700;
  font-size: var(--fontSize1);
  line-height: 28px;
  color: #15011e;
}
