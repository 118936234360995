.switchInnerDiv {
  padding: 15px;
}

.dropdownDiv {
  width: 280px;
  background: rgba(249, 249, 249, 1);
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
}

.inputDropdownDiv {
  width: 250px;
  background: rgba(249, 249, 249, 1);
  font-size: 12px;
  font-weight: 400;
  border: none;
  outline: none;
}
