.profileMain {
  background-color: white !important;
}

.profileTop {
  display: flex;
  margin: 8px;
  margin-top: 30px;
  gap: 24px;
  width: 384px;
  align-items: center;
}

.profileTop > span > h1 {
  font-weight: var(--fontWeight1) !important;
  font-size: var(--fontSize1) !important;
  line-height: 20px !important;
  color: var(--textColor) !important;
}

.profileTop > span > p {
  font-weight: var(--fontWeight2);
  font-size: var(--fontSize2);
  line-height: 16px;
  color: var(--textColorActive) !important;
  margin-top: 16px;
  cursor: pointer;
}
